import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Password, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, FirebaseContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Message, ValidateEmail } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import ResetPassword from "./resetPassword";
import { withNamespaces } from "react-i18next";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { silentOTP } from "./silentOTP";



const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100% 25%",
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  img: {
    height: "8%",
    width: "28%",
  },
  forgetPassword: {
    textAlign: "right",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "10px",
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  version: {
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
    color: "#091B29",
  }
}));

const InitialState = {
  email: "",
  password: "",
  error: {
    email: "",
    password: "",
  },
};
const Login = (props) => {
  const { t } = props;
  const classes = useStyles();
  const size = useWindowDimensions();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [login, setLogin] = React.useState({ ...InitialState });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const search = useLocation().search;
  const [disable, setDisable] = React.useState(false)
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  const [showDrawer, setShowDrawer] = React.useState(false);
  const firebaseContext = React.useContext(FirebaseContext);


  React.useEffect(() => {
    if (localStorage.getItem("device_token")) {
    } else {
      firebaseContext.requestPermission();
    }
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      let Token = jwt_decode(welcomePageAuthToken)
      setLogin({
        ...login,
        email: Token.email_id
      })

    } else if (authToken !== null && authToken !== "") {
      history.push(Routes.dashboard);
    } else {
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [history]);
  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = login.error;

    if (login.email.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage(" Enter your email/mobile/UCID");
    }
    if (login.password.length === 0) {
      isValid = false;
      error.password = Message.English.requiredMessage("Password");
    }
    if (login.email !== "" && login.password !== "") {
      isValid = true;
    }
    setLogin({ ...login, error });
    return isValid;
  };

  // eslint-disable-next-line
  const onSendLoginBtnClicked = (e) => {
    e.preventDefault();
    let data = {
      // tenantId: `${config.tenantid}`,
      password: login.password,
    };
    if (isIamValideToLogin()) {
      // Check phone number
      //eslint-disable-next-line
      var isphone = /^\d+$/.test(login.email)
      // Check email id
      //eslint-disable-next-line
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(login.email);
      if (isphone) {
        data["mobile_no"] = login.email;
      } else if (email) {
        data["email_id"] = login.email;
      } else {
        data["ucid"] = login.email;
      }
      NetworkCall(
        `${config.auth_api_url}/auth/login`,
        NetWorkCallMethods.post,
        data,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 200) {
            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
              history.push(
                Routes.otpPage + "?welcomeAuthToken=" + welcomePageAuthToken
              );
            } else {
              history.push(
                Routes.otpPage +
                "?data=" +
                (login.email)
              );
            }
            localStorage.setItem(LocalStorageKeys.authToken, response.data.token)

          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Credientials",
          });
          localStorage.clear();
        });
    } else {
      return false;
    }
  };
  const onDrawerClose = () => {
    setShowDrawer(false);
  };
  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onSendLoginBtnClicked()
    }
  }

  return (<div>
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <form
        onSubmit={(e) => {
          setDisable(true)
          silentOTP(e, login, alert, history, isIamValideToLogin, setDisable, welcomePageAuthToken)
        }}
        autoComplete="on">
        <Grid container>
          <Grid item className={classes.content}
            style={{ height: size.height > 620 ? size.height - 94 : 527 }}>
            <Grid item xs={12} className={classes.backgroundImage} />
            <Grid style={{ padding: "0px 16px 0px 16px" }}>
              <Box />
              <Box height={"16px"} />
              <Grid item xs={12}>
                <Typography className={classes.text}>
                  Log in
                </Typography>
              </Grid>
              <Box height={"16px"} />
              <Grid item xs={12}>
                <TextBox
                  value={login.email} isRequired
                  onChange={(e) => updateState("email", e.target.value)}
                  label={Message.English.login.email.label}
                  placeholder={Message.English.login.email.placeholder}
                  isError={login.error.email.length > 0}
                  errorMessage={login.error.email} />
              </Grid>
              <Box height={"16px"} />
              <Grid item xs={12}>
                <Password
                  value={login.password} isRequired
                  onChange={(e) => updateState("password", e.target.value)}
                  label={Message.English.login.password.label}
                  placeholder={Message.English.login.password.placeholder}
                  isError={login.error.password.length > 0}
                  errorMessage={login.error.password}
                  onKeyPress={(e) => handleEnter(e)} />
                <Box height={"20px"} />
                <Grid item xs={12}
                  justifyContent={"end"}
                  alignItems={"center"}>
                  <Typography
                    onClick={() => setShowDrawer(true)}
                    className={classes.forgetPassword}>
                    Forget password ?
                  </Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent={"space-between"}
                  alignContent={"center"}
                  mt={1}
                >
                  <img
                    src="/images/PA Logo.svg"
                    alt="logo"
                    className={classes.img}
                  />
                  <Typography className={classes.version}>{"Ver " + localStorage.getItem(LocalStorageKeys.version)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
            <Button
              fullWidth
              disabled={disable}
              variant="contained"
              className={classes.btn}
              // onClick={onSendLoginBtnClicked}
              type="submit">
              <Typography className={classes.buttonTextStyle}>
                {t("Log_In")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* Drawer */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          open={showDrawer}
          anchor="bottom"
          onClose={() => setShowDrawer(false)}
        >
          <Container maxWidth="sm" style={{ overflow: "hidden" }}>
            <div className={classes.contentBottom}>
              <Box
                display="flex"
                p={2}
                alignItems="center"
                className={classes.titleroot}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.bottomTitle}>
                    {t("Reset_Password")}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => setShowDrawer(false)}
                  >
                    <CloseIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <ResetPassword onDrawerClose={onDrawerClose} />
            </div>
          </Container>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog fullWidth={true} maxWidth="sm" open={showDrawer}>
          <Container maxWidth="sm" style={{ overflow: "hidden" }}>
            <div className={classes.contentBottom}>
              <Box
                display="flex"
                p={2}
                alignItems="center"
                className={classes.titleroot}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.bottomTitle}>
                    {t("Reset_Password")}

                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => setShowDrawer(false)}
                  >
                    <CloseIcon color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <ResetPassword onDrawerClose={onDrawerClose} />
            </div>
          </Container>
        </Dialog>
      </Hidden>
    </Container>
  </div>);
};
export default withNamespaces("loginPage")(Login)
