import { Avatar, Stack, Typography } from "@mui/material"
import GateOne from "../../../assets/gate_1"
import PhoneOne from "../../../assets/phone_1"
import { useStyles } from "../style"

export const SecurityGuard = (props) => {
    const {
        item = {},
        index = 0,
        array = []
    } = props
    //const
    const classes = useStyles(props)

    return <div className={classes.security_card_root}>
        <div className={classes.security_card}>
            <div className={classes.gate_info_div}>
                <Stack className={classes.gate_info}
                    direction={"row"} spacing={"4px"}>
                    <GateOne />
                    <Stack direction={"row"} spacing={"4px"}>
                        <Typography className={classes.gate_no_label}>
                            {"Gate No:"}
                        </Typography>
                        <Typography className={classes.gate_no_value}>
                            {item?.gate_no}
                        </Typography>
                    </Stack>
                </Stack>
            </div>
            <div className={classes.guard_info_div}>
                <Stack direction={"column"} spacing={"12px"}>
                    <Stack direction={"row"} spacing={"12px"}
                        className={classes.guard_info_div_1}>
                        <Stack direction={"row"} spacing={"12px"}
                            className={classes.guard_info_div_2}>
                            <Avatar variant="circular" src={item?.profile_pic ?? "/images/avatar.jpg"} alt="" />
                            <Stack direction={"column"} spacing={"6px"}>
                                <Typography className={classes.guard_name}>
                                    {item?.name}
                                </Typography>
                                <Typography className={classes.guard_no}>
                                    {item?.mobile_code ?? ""} {item?.mobile_no ?? ""}
                                </Typography>
                            </Stack>
                        </Stack>
                        <a href={`tel:${item?.mobile_code}+${item?.mobile_no}`}>
                            <div className={classes.call_button}>
                                <PhoneOne />
                            </div>
                        </a>
                    </Stack>
                    <Stack direction={"row"} spacing={"2px"} style={{ alignItems: "center", justifyContent: "center" }}>
                        {array?.map((i, ind) =>
                            ind === index ?
                                <div style={{ height: "8px", width: "8px", backgroundColor: "#5078E1", borderRadius: "50%" }} /> :
                                <div style={{ height: "8px", width: "8px", backgroundColor: "#E4E8EE", borderRadius: "50%" }} />)}
                    </Stack>
                </Stack>
            </div>
        </div>
    </div>
}