import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
import { Bold } from "../../utils";

const useStyles = makeStyles((theme) => ({
    card: {
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: theme.palette.background.paper,
        margin: "auto 4px",

        boxShadow: "0px 3px 30px #5C86CB2E"
    },
    title: {
        fontSize: "16px",
        color: theme.typography.color.primary,
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    },
    progress: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        background: "#78B1FE",
        padding: "1px 8px",
        // borderRadius: "4px",
    },
    approved: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        background: "#5AC782",
        padding: "1px 8px",
    },
    delete: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        background: "red",
        padding: "1px 8px",
    },
    cancel: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        background: "red",
        padding: "1px 8px",
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    sub: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    },
    img: {
        borderRadius: theme.palette.borderRadius,
        height: "120px",
        width: "101px"
    },
    rejected: {
        fontSize: "12px",
        color: "#FFFFFF",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        background: "#EC903F",
        padding: "1px 8px",
    },
    boxes: {
        flexFlow: 'wrap !important'
    },
    avatar: {
        height: "122px",
        width: "100%",
        objectFit: "cover",
        [theme.breakpoints.down(321)]: {
            height: "140px",

        },
    },
    bold: {
        fontSize: "12px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: theme.typography.color.secondary,
    },
    semiBold: {
        fontSize: "12px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        color: theme.typography.color.secondary,

    },
    bottomText: {
        bottom: 0,
        borderRadius: "4px",
        backgroundColor: "#071741",
        color: "white",
        width: "110px",
        padding: "4px",
        textAlign: "center"
    },
    bottomText1: {
        position: "absolute",
        bottom: 0,
        color: "white",
        textAlign: "center",
        right: "0px",
        left: "0px"
    },
    flag: {
        backgroundColor: (props) => props?.data?.request_type === "VGR" ? "#6D6DB3" : "#5078E1",
        padding: "2px 8px",
        position: "absolute",
        top: "4px",
        right: "-10px",
        borderRadius: "4px",
        boxShadow: '0px 3px 6px #00000029'
    },
    flagChild: {
        height: "10px",
        width: "11px",
        backgroundColor: (props) => props?.data?.request_type === "VGR" ? "#6D6DB3" : "#5078E1",
        bottom: "-8px",
        right: "-8px",
        position: "absolute",
        borderBottomRightRadius: '195px'
    },
    flagName: {
        color: "white",
        fontSize: "14px",
        fontFamily: Bold,
    },
}));

export const PassCard = (props) => {
    const classes = useStyles(props);
    const history = useHistory();

    console.log(props?.data)

    return (
        <Box sx={{ position: "relative" }}>
            <Grid container className={classes.card}
                onClick={() =>
                    history.push(Routes.passDetails + "?passId=" + props?.data?.id)
                }
            >

                <Grid item xs={4} md={3}>
                    <Box style={{ position: "relative" }}>
                        {props?.data?.visitors?.[0]?.visitor_image ?
                            <img src={props?.data?.visitors?.[0]?.visitor_image} alt="" className={classes.avatar} /> :
                            <Avatar className={classes.avatar} variant="square" />
                        }

                    </Box>
                </Grid>
                <Grid item xs={8} md={9} alignItems="center" sx={{ padding: "12px" }}>
                    <Typography className={classes.title}>
                        {props?.data?.visitors_count > 0 ? props?.data?.visitors[0]?.name :
                            <>
                                {props?.data?.request_type === "VGR" && "Visitor"}
                                {props?.data?.request_type === "WGR" && "Worker"}
                            </>
                        }
                    </Typography>
                    {
                        props?.data?.visitors_count > 0 &&
                        <Stack direction="row" marginTop="6px" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                            <Typography

                                variant="subtitle2"
                                className={classes.sub}
                                noWrap
                            >
                                {props?.data?.visitors?.[0]?.mobile_country_code} {props?.data?.visitors?.[0]?.mobile_no}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={classes.sub}
                                noWrap
                            >
                                {`Family ${props?.data?.visitors?.length - 1 > 0 ? `+${props?.data?.visitors?.length - 1}` : ""}`}
                            </Typography>
                        </Stack>
                    }
                    <Stack direction="row" marginTop="6px" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
                        <Typography

                            variant="subtitle2"
                            className={classes.sub}
                            noWrap
                        >
                            {props?.data?.property_name}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className={classes.sub}
                            noWrap
                        >
                            {props?.data?.unit?.[0]?.unit_no ?? props?.data?.unit_no ?? "-"}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} marginTop="6px">
                        <DateIcon />
                        <Typography
                            variant="subtitle2"
                            className={classes.bold}
                            noWrap
                        >
                            {moment(props?.data?.request_from)
                                .tz(moment.tz.guess())
                                .format("DD MMM YY")}
                            &nbsp;-&nbsp;
                            {moment(props?.data?.request_to)
                                .tz(moment.tz.guess())
                                .format("DD MMM YY ")}
                        </Typography>
                    </Stack>

                </Grid>
            </Grid>
            <Box className={classes.flag}>
                <Box position={"relative"}>
                    <Typography className={classes.flagName} noWrap>
                        {props?.data?.request_type === "VGR" && "Visitor"}
                        {props?.data?.request_type === "WGR" && "Worker"}
                    </Typography>
                    <Box className={classes.flagChild} />
                </Box>
            </Box>
        </Box>
    );
};
