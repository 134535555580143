import { useHistory } from "react-router-dom"
import { Filter, LoaderSimmer, Search, TitleBar } from "../../components"
import { useStyles } from "./style"
import { SecurityGuard } from "./component/securityCard"
import useWindowDimensions from "../../utils/useWindowDimensions"
import ScrollContainer from "react-indiana-drag-scroll";
import { TabComponent } from "./component/tabComponent"
import React from "react";
import { Box, Dialog, Drawer, Hidden } from "@mui/material"
import { VisitorParking } from "./visitorParking"
import { ReservedParking } from "./reservedParking"
import { CommonParking } from "./commonParking"
import { ViewParkingSlot } from "./component/viewParkingSlot"
import { AlertProps, availabilityOptions, enumSelect, enumTypes, LocalStorageKeys, NetWorkCallMethods } from "../../utils"
import jwt_decode from "jwt-decode";
import { GET_GATE_MASTER, GET_PARKING_AREA, GET_PARKING_GROUPING_MASTER, SECURITY_ACCESS, SECURITY_MAIN } from "../../graphql/queries"
import { NetworkCall } from "../../networkcall"
import { config } from "../../config"
import { AlertContext } from "../../contexts"
import { UseDebounceNew } from "../../hooks"


export const Info = () => {

    //const
    const dimension = useWindowDimensions()
    const history = useHistory()
    const classes = useStyles()
    const debounce = UseDebounceNew()
    const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
    const alert = React.useContext(AlertContext)

    //useState
    const [selectedTab, setSelectedTab] = React.useState(0)
    const [openView, setOpenView] = React.useState(false)
    const [viewData, setViewData] = React.useState({})
    const [guardDetails, setGuardDetails] = React.useState([])
    const [currentTab, setCurrentTab] = React.useState("Visitors")
    const [search, setSearch] = React.useState("")
    const [filter, setFilter] = React.useState(false);
    const [selectedFilter, setSelectedFilter] = React.useState({});
    const [enumValue, setEnumValue] = React.useState({
        vehicle_type: [],
        parking_area_type: [],
    })
    const [commonParkingList, setCommonParkingList] = React.useState([])
    const [propertyID, setPropertyID] = React.useState("")
    const [loading, setLoading] = React.useState(true)
    const [parkingGroup, setParkingGroup] = React.useState([])
    const [visitorReservedParkingList, setVisitorReservedParkingList] = React.useState([])

    const handleOpenView = (value) => {
        setViewData(value)
        setOpenView(!openView)
    }

    React.useEffect(() => {
        getEnum()
        let authToken = localStorage.getItem(LocalStorageKeys.authToken)
        let decode = jwt_decode(authToken)
        const payload = {
            query: SECURITY_MAIN,
            variables: { id: decode.id, clientId: clientId },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((res) => {
            let tempRes = res?.data?.data;
            let security_id = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
            localStorage.setItem("user_profile_id", tempRes?.user_profiles?.[0]?.id)
            if (tempRes.user_profiles[0].id) {
                const payload = {
                    query: SECURITY_ACCESS,
                    variables: {
                        user_profile_id: tempRes?.user_profiles?.[0]?.id,
                        role_id: security_id?.[0]?.id,
                        clientId: clientId
                    },
                };
                NetworkCall(
                    `${config.graphql_url}`,
                    NetWorkCallMethods.post,
                    payload, null, true, false
                ).then((res) => {
                    let tempRes = res?.data?.data?.access_control;
                    let tempPropertyID = tempRes[0]?.property?.label
                    setPropertyID(tempPropertyID)
                    getParkingGroup(tempPropertyID)
                    const payload = {
                        query: GET_GATE_MASTER,
                        variables: {
                            property_id: tempRes[0]?.property?.label,
                            clientId: clientId
                        },
                    };
                    NetworkCall(
                        `${config.graphql_url}`,
                        NetWorkCallMethods.post,
                        payload, null, true, false
                    ).then((res) => {
                        let tempRes = res?.data?.data?.access_gates_master
                        let tempGuardDetails = tempRes?.map((i) => {
                            return {
                                gate_no: i?.gate_no ?? "-",
                                name: i?.user_profilesByID?.first_name ?? "-",
                                mobile_code: i?.user_profilesByID?.mobile_no_country_code,
                                mobile_no: i?.user_profilesByID?.mobile_no,
                                profile_pic: i?.user_profilesByID?.image_url,
                            }
                        })
                        setGuardDetails(tempGuardDetails)
                    }).catch((e) => {
                        console.log(e)
                        alert.setSnack({
                            ...alert, open: true, msg: "Something Went Wrong",
                            severity: AlertProps.severity.error,
                        });
                    });

                }).catch((e) => {
                    console.log(e)
                    alert.setSnack({
                        ...alert, open: true, msg: "Something Went Wrong",
                        severity: AlertProps.severity.error,
                    })
                })
            }
        }).catch((e) => {
            console.log(e)
            alert.setSnack({
                ...alert, open: true, msg: "Something Went Wrong",
                severity: AlertProps.severity.error,
            })
        })
        // eslint-disable-next-line
    }, [])

    const tabOnClick = (tab) => {
        setLoading(true)
        switch (tab) {
            case 0:
                setSelectedTab(0)
                setCurrentTab("Visitors")
                setSearch("")
                setSelectedFilter({})
                getVisitorReservedParking("Visitors", parkingGroup, "", {})
                break;
            case 1:
                setSelectedTab(1)
                setCurrentTab("Reserved")
                setSearch("")
                setSelectedFilter({})
                getVisitorReservedParking("Reserved", parkingGroup, "", {})
                break;
            case 2:
                setSelectedTab(2)
                setCurrentTab("Common")
                setSearch("")
                setSelectedFilter({})
                getCommonParking(clientId, propertyID, search)
                break;
            default:
                break;
        }
    }

    const visitorReservedParkingFilter = [
        {
            id: "1",
            title: "Parking Group",
            key: "parkingGroup",
            filterType: "CHECKBOX",
            values: parkingGroup
        },
        {
            id: "2",
            title: "Availability",
            key: "availability",
            filterType: "CHECKBOX",
            values: availabilityOptions,
        },
        {
            id: "3",
            title: "Vehicle",
            key: "vehicle",
            filterType: "CHECKBOX",
            values: enumValue?.vehicle_type,
        },
    ];

    const commonParkingFilter = [
        {
            id: "4",
            title: "Parking Area Type",
            key: "parkingAreaType",
            filterType: "CHECKBOX",
            values: enumValue?.parking_area_type,
        }
    ];

    const getEnum = async () => {
        const result = await enumSelect([enumTypes?.vehicle_type, enumTypes?.parking_area_type])
        setEnumValue({ ...enumValue, vehicle_type: result?.vehicle_type, parking_area_type: result?.parking_area_type })
    }

    const handleSearch = (e) => {
        setSearch(e)
        debounce(() => {
            if (currentTab === "Common") {
                getCommonParking(clientId, propertyID, e, selectedFilter)
            } else {
                getVisitorReservedParking(currentTab, parkingGroup, e, selectedFilter)
            }
        })
    }

    const getVisitorReservedParking = (
        current_tab = currentTab,
        parking_group = parkingGroup,
        search_text = search,
        filter = selectedFilter
    ) => {
        let payload = {
            parking_type: [current_tab],
            grouping_master_id: (filter?.parkingGroup && filter?.parkingGroup?.length > 0) ?
                filter?.parkingGroup : parking_group?.map((i) => i?.value),
            search: search_text,
            available: filter?.availability,
            vehicle_type: filter?.vehicle,
        };
        NetworkCall(
            `${config.api_url}/parking/parkingSlotAvailabilityInfo`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((res) => {
            let tempRes = res?.data?.data
            setVisitorReservedParkingList(tempRes)
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            alert.setSnack({
                ...alert, open: true, msg: "Something Went Wrong",
                severity: AlertProps.severity.error,
            });
        });
    }

    const getCommonParking = (
        client_ID = clientId,
        property_ID = propertyID,
        search_text = search,
        filter = selectedFilter
    ) => {
        let payload = {};
        let variables = {};
        variables = { client_ID, property_ID, search_text }
        if (filter?.parkingAreaType?.length > 0) {
            variables["parkingAreaType"] = filter?.parkingAreaType
        }
        payload = { query: GET_PARKING_AREA(filter?.parkingAreaType)?.loc?.source?.body, variables };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((res) => {
            let tempRes = res?.data?.data?.parking_area
            setCommonParkingList(tempRes)
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            alert.setSnack({
                ...alert, open: true, msg: "Something Went Wrong",
                severity: AlertProps.severity.error,
            });
        });
    }

    const getParkingGroup = (property_ID = propertyID) => {
        let payload = {
            query: GET_PARKING_GROUPING_MASTER,
            variables: { property_ID }
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload, null, true, false
        ).then((res) => {
            let tempParkingGroup = res?.data?.data?.parking_grouping_master
            setParkingGroup(tempParkingGroup)
            getVisitorReservedParking(currentTab, tempParkingGroup, search, selectedFilter)
        }).catch((e) => {
            console.log(e)
            alert.setSnack({
                ...alert, open: true, msg: "Something Went Wrong",
                severity: AlertProps.severity.error,
            });
        });
    }
    //apply filter
    const applyFilter = (data) => {
        setLoading(true)
        setSelectedFilter(data)
        if (currentTab === "Common") {
            getCommonParking(clientId, propertyID, search, data)
        } else {
            getVisitorReservedParking(currentTab, parkingGroup, search, data)
        }
        openCloseFilter()
    }

    const openCloseFilter = () => {
        setFilter(!filter)
    }

    return (
        <div>
            <TitleBar
                text="Info"
                color="#5078E1"
                goBack={() => history.goBack(-1)} />
            <div className={classes.root}>
                <ScrollContainer className="scroll-container">
                    <div className={classes.security_card_div}>
                        {guardDetails?.map((item, index, array) =>
                            <SecurityGuard item={item}
                                index={index} array={array}
                                dimension={dimension} />)}
                    </div>
                </ScrollContainer>
                <div className={classes.tab_div}>
                    <TabComponent
                        selectedTab={selectedTab}
                        onTab1Clicked={() => tabOnClick(0)}
                        onTab2Clicked={() => tabOnClick(1)}
                        onTab3Clicked={() => tabOnClick(2)}
                    />
                    <Box height={"4px"} />
                    <div className={classes.search_filter_div}>
                        <Search value={search} onChange={handleSearch} />
                        <Box width={"8px"} />
                        <div className={classes.call_button} onClick={openCloseFilter}>
                            <img src='images/icons8-funnel.svg' alt="" />
                        </div>
                    </div>
                    {loading ?
                        <>
                            <LoaderSimmer />
                            <LoaderSimmer />
                        </> :
                        <>
                            {selectedTab === 0 &&
                                <VisitorParking dimension={dimension}
                                    data={visitorReservedParkingList} handleOpenView={handleOpenView} />}
                            {selectedTab === 1 &&
                                <ReservedParking dimension={dimension}
                                    data={visitorReservedParkingList} handleOpenView={handleOpenView} />}
                            {selectedTab === 2 &&
                                <CommonParking dimension={dimension}
                                    data={commonParkingList} />}
                        </>}
                </div>
            </div>
            <ViewParkingSlot data={viewData}
                open={openView} handleOpenView={handleOpenView} />
            <Hidden smDown>
                <Dialog
                    className={classes.dialog_1}
                    maxWidth="sm"
                    fullWidth
                    open={filter}
                    onClose={openCloseFilter}>
                    <Filter
                        filterData={currentTab === "Common" ? commonParkingFilter : visitorReservedParkingFilter}
                        selectedList={currentTab === "Common" ? commonParkingFilter[0] : visitorReservedParkingFilter[0]}
                        filters={currentTab === "Common" ?
                            {
                                parkingAreaType: selectedFilter.parkingAreaType
                            } :
                            {
                                parkingGroup: selectedFilter.parkingGroup,
                                availability: selectedFilter.availability,
                                vehicle: selectedFilter.vehicle
                            }}
                        setSelectedFilter={setSelectedFilter}
                        onGoBack={openCloseFilter}
                        onApply={applyFilter}
                        onClose={openCloseFilter} />
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer open={filter} onClose={openCloseFilter}>
                    <Filter
                        filterData={currentTab === "Common" ? commonParkingFilter : visitorReservedParkingFilter}
                        selectedList={currentTab === "Common" ? commonParkingFilter[0] : visitorReservedParkingFilter[0]}
                        filters={currentTab === "Common" ?
                            {
                                parkingAreaType: selectedFilter.parkingAreaType
                            } :
                            {
                                parkingGroup: selectedFilter.parkingGroup,
                                availability: selectedFilter.availability,
                                vehicle: selectedFilter.vehicle
                            }}
                        setSelectedFilter={setSelectedFilter}
                        onGoBack={openCloseFilter}
                        onApply={applyFilter}
                        onClose={openCloseFilter} />
                </Drawer>
            </Hidden>
        </div>)
}