import React from "react";
import { withBottombar } from "./../../HOCs";
import { Requset } from "./requset";

class RequsetParent extends React.Component {
  render() {
    return <Requset />;
  }
}

export default withBottombar(RequsetParent);
