import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { EnterOtp } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import { withNamespaces } from "react-i18next";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: theme.palette.primary.main,
    height: "213px",
    backgroundImage: `url("images/bgNew.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%",
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  img: {
    height: "8%",
    width: "28%",
  },
  verificationText: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    textAlign: "center",
    color: theme.typography.color.primary,
    backgroundColor: theme.palette.background.secondary,
    borderRadius: "15px",
    padding: "20px",
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontSize: "8px",
    color: theme.typography.color.Tertiary,
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "10px",
  },
}));

const OtpPage = (props) => {
  const { t } = props;
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const token = localStorage.getItem(LocalStorageKeys.authToken)

  const alert = React.useContext(AlertContext);
  const [value, setValue] = React.useState("");
  const handleChange = (otp) => {
    setValue(otp);
  };
  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  const emailId = new URLSearchParams(search).get("data");
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);

  const verifyOtp = (e) => {
    e.preventDefault();
    const payload = {
      otp: value,
    };
    // Check phone number
    // //eslint-disable-next-line
    // var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(emailId);
    // // Check email id
    // //eslint-disable-next-line
    // var email = /^\w+([\.-]?\ w+)*@\w+([\.-]?\ w+)*(\.\w{2,3})+$/.test(emailId);
    // if (isphone) {
    //   payload["mobile_no"] = emailId;
    // } else if (email) {
    //   payload["email_id"] = emailId;
    // } else {
    //   payload["customer_id"] = emailId;
    // }
    NetworkCall(
      `${config.auth_api_url}/auth/verifyOTP/?token=${token}`,
      NetWorkCallMethods.post,
      payload,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(LocalStorageKeys.authToken, response.data.token);

          // const roles = response.data.roles.map((val) => {
          //   return val.name;
          // });
          // const roleslist = response.data.roles.filter(
          //   (val) => val.name === "Security Guard"
          // );
          // const even = (roles) => roles === "Security Guard";

          // if (roles.some(even)) {
          //   localStorage.setItem(
          //     LocalStorageKeys.authToken,
          //     response.data.token
          //   );
          //   localStorage.setItem("roles", JSON.stringify(roleslist));
          //   localStorage.setItem("selectedroles", JSON.stringify(roleslist[0]));
          //   localStorage.setItem("clientLogo", response.data.client_logo)
          //   history.push(Routes.dashboard);
          // } else {
          //   alert.setSnack({
          //     ...alert,
          //     open: true,
          //     severity: AlertProps.severity.error,
          //     msg: "Sorry You Don't Have Access",
          //   });
          // }
          history.push({
            pathname: Routes.companyselect,
            state: response.data.user.clients
          });
        } else {
          localStorage.setItem(LocalStorageKeys.authToken, null);
          history.push(Routes.login);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.setItem(LocalStorageKeys.authToken, null);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: " Invalid OTP",
        });
      });
  };

  return (<div>
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <form onSubmit={verifyOtp}>
        <Grid container>
          <Grid item className={classes.content}
            style={{ height: size.height > 643 ? size.height - 94 : 549 }}>
            <Grid item xs={12} className={classes.backgroundImage} />
            <Grid style={{ padding: "0px 16px 0px 16px" }}>
              <Box height={"24px"} />
              <Grid container xs={12} direction="row"
                justifyContent={"space-between"}
                alignContent={"center"}>
                <img
                  src="/images/PA Logo.svg"
                  alt="logo"
                  className={classes.img} />
              </Grid>
              <Box height={"16px"} />
              <Grid item xs={12}>
                <Typography className={classes.text}>
                  {t("Enter_OTP")}
                </Typography>
              </Grid>
              <Box height={"24px"} />
              <Grid item xs={12}>
                <EnterOtp
                  value={value}
                  handleChange={handleChange}
                  emailId={
                    decoded && decoded.email_id ? decoded.email_id : emailId
                  } />
              </Grid>
              <Box height={"16px"} />
              <Grid item xs={12}>
                <Typography className={classes.verificationText}>
                  Verification code has been sent to your email & mobile number
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
            <Button
              fullWidth variant="contained"
              className={classes.btn} type='submit'
              disabled={value.length === 4 ? false : true}>
              <Typography className={classes.buttonTextStyle}>
                {t("Verify")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  </div>);
};
export default withNamespaces("otpPage")(OtpPage)
