import React from "react";
import { ParkingSlotDetails } from "./parkingSlotDetails";

class ParkingSlotDetailsParent extends React.Component {
  render() {
    return <ParkingSlotDetails />;
  }
}

export default ParkingSlotDetailsParent;
