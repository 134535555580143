import React from 'react';
import { Box, Button, Container, Grid, IconButton, Typography, Hidden, Drawer, Dialog, FormControl, RadioGroup, FormControlLabel, Radio, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useLocation } from 'react-router-dom';
import { TextBox, TitleBar } from '../../components';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useWindowDimensions from "../../utils/useWindowDimensions";
import QrReader from "react-qr-reader";
import CloseIcon from "@mui/icons-material/Close";
import jwt_decode from "jwt-decode";
import { GET_GATE_MASTER, SECURITY_ACCESS, SECURITY_MAIN } from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import { config } from "../../config";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";







const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.main,
    },
    text: {
        color: theme.typography.color.primary,
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "6px"
    },
    bottomBarTitle: {
        fontSize: "18px",
        fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
        color: theme.typography.color.primary,
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "8px",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    iconButtonStyle: {
        height: "44px",
        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        color: "#5078E1",
        background: "#5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "0px 2px 6px #00000021",
    },
    saveNUpdateButtonTextStyle: {
        fontSize: "14px",
        color: "#FFFFFF",
        fontWeight: "bold",
    },
    saveNUpdateButtonContainerStyle: {
        padding: "16px",
        background: "white",
        border: "1px solid #E4E8EE",
        boxShadow: "0px -7px 20px #0717410D",
        // position: "fixed",
        width: "-webkit-fill-available",
    },
    profilePicturePadding: {
        justifyContent: "center",
        padding: "24px 16px 24px 16px",
    },
    qrcode: {
        border: "10px solid #5078E1",
        borderRadius: "8px",
        "& section > div": {
            border: "none !important",
            boxShadow: "none !important",

        }
    }
}))
const GetPassVerification = props => {
    const { t } = props
    const history = useHistory();
    const classes = useStyles();
    const size = useWindowDimensions();
    const [gateOpen, setGateOpen] = React.useState(false)
    const alert = React.useContext(AlertContext);
    const [open, setOpen] = React.useState(false)
    const [gateNo, setGateNo] = React.useState([])
    const [selected, setSelected] = React.useState({})
    const [qrCode, setQrCode] = React.useState("")
    const searchURL = useLocation().search;
    const Domestic = new URLSearchParams(searchURL).get("title");
    const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;


    const handleError = (err) => {
        console.error(err);
    };

    //qr api
    const QrCodeApi = (val) => {
        if (val) {
            if (Domestic === "Domestic") {
                history.push(`${Routes.gateEntry}${"?qrcode="}${val}${"&domestic="}${"Domestic"}${"&property_id="}${selected?.property?.id}${"&gate_id="}${selected?.id}`)
            }
            else {
                history.push(Routes.gateEntry + "?qrcode=" + val)
            }
        }
    }

    React.useEffect(() => {
        let authToken = localStorage.getItem(LocalStorageKeys.authToken);
        let decode = jwt_decode(authToken);
        const payload = {
            query: SECURITY_MAIN,
            variables: {
                id: decode.id,
                clientId: clientId

            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                let main = response?.data?.data;
                let security_id = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))
                localStorage.setItem("user_profile_id", main?.user_profiles?.[0]?.id)
                if (main.user_profiles[0].id) {
                    const payload = {
                        query: SECURITY_ACCESS,
                        variables: {
                            user_profile_id: main?.user_profiles?.[0]?.id,
                            role_id: security_id?.[0]?.id,
                            clientId: clientId

                        },
                    };
                    NetworkCall(
                        `${config.graphql_url}`,
                        NetWorkCallMethods.post,
                        payload,
                        null,
                        true,
                        false
                    )
                        .then((response) => {
                            let main2 = response?.data?.data?.access_control;
                            const data = {
                                query: GET_GATE_MASTER,
                                variables: {
                                    property_id: main2[0]?.property?.label,
                                    clientId: clientId

                                },
                            };
                            NetworkCall(
                                `${config.graphql_url}`,
                                NetWorkCallMethods.post,
                                data,
                                null,
                                true,
                                false
                            )
                                .then((response) => {
                                    let data = response?.data?.data?.access_gates_master
                                    let selectedId = data?.filter((val) => val.user_profile_id === main.user_profiles[0].id)[0]

                                    setSelected(selectedId ? selectedId : data?.[0])

                                    setGateNo(data)

                                }).catch((err) => {
                                    console.log(err);
                                    alert.setSnack({
                                        ...alert,
                                        open: true,
                                        severity: AlertProps.severity.error,
                                        msg: "Something Went Wrong",
                                    });
                                });

                        })
                        .catch((error) => {
                            console.log(error);
                            alert.setSnack({
                                ...alert,
                                open: true,
                                severity: AlertProps.severity.error,
                                msg: "Something Went Wrong",
                            });
                        });
                }
            })
            .catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something Went Wrong",
                });
            });
        // eslint-disable-next-line
    }, []);
    return <div >
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
            <TitleBar
                text={Domestic === "Domestic" ? t("Domestic_Pass_Verification") : t("Gate_Pass_Verification")}
                // color="#5078E1"
                goBack={() => history.push(Routes.dashboard)}
            />
            <div className={classes.root}>
                <Grid Container
                    style={{ height: size?.height - (88), width: "inherit", overflow: "auto" }}
                >
                    <br />
                    <center>
                        <span className={classes.text}>
                            &nbsp;{selected?.name} &nbsp;
                            <IconButton
                                size="small"
                                style={{
                                    color: "black",
                                }}
                                onClick={() => setGateOpen(true)}
                            >
                                <KeyboardArrowDownIcon />
                            </IconButton>
                        </span>
                    </center>
                    <br />
                    <center>
                        <Grid item xs={12} style={{ overflow: "auto" }}>
                            <QrReader
                                delay={500}
                                onError={handleError}
                                onScan={QrCodeApi}
                                style={{ width: "320px" }}
                                className={classes.qrcode}
                            />
                        </Grid>
                        <Grid item xs={12}>{t("Scan_QR_Code")}</Grid>
                    </center>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant='text'
                            onClick={() => setOpen(true)}
                        >
                            {t("Enter_QR_Number_Manually")}
                        </Button>
                        <br />
                    </Grid>
                </Grid>
            </div>
            {/* gate  hidden */}
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={gateOpen}
                    onClose={() => setGateOpen(false)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography
                                variant="subtitle2"
                                className={classes.bottomBarTitle}
                            >
                                {t("Select_Gate")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton size="small" onClick={() => setGateOpen(false)}>
                                <CloseIcon color="secondary" />
                            </IconButton>
                        </Box>
                    </Box>
                    <FormControl component="fieldset" >
                        <RadioGroup
                            aria-label="gender"
                            defaultValue="female"
                            name="radio-buttons-group"
                            style={{ padding: "8px" }}
                        >
                            {gateNo?.map((val) => {
                                return (
                                    <>
                                        <FormControlLabel
                                            value={val?.gate_no}
                                            control={
                                                <Radio
                                                    checked={
                                                        val?.id === selected?.id
                                                            ? true
                                                            : false
                                                    }


                                                />
                                            }
                                            label={val?.name}
                                            onChange={() => setSelected(val)}
                                        />

                                        <Divider />
                                    </>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>

                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={gateOpen}
                    onClose={() => setGateOpen(false)}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography
                                variant="subtitle2"
                                className={classes.bottomBarTitle}
                            >
                                {t("Select_Gate")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton size="small" onClick={() => setGateOpen(false)}>
                                <CloseIcon color="secondary" />
                            </IconButton>
                        </Box>
                    </Box>
                    <FormControl component="fieldset" >
                        <RadioGroup
                            aria-label="gender"
                            defaultValue="female"
                            name="radio-buttons-group"
                            style={{ padding: "8px" }}
                        >
                            {gateNo?.map((val) => {
                                return (
                                    <>
                                        <FormControlLabel
                                            value={val?.gate_no}
                                            control={
                                                <Radio
                                                    checked={
                                                        val?.id === selected?.id
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            }
                                            label={val?.name}
                                            onChange={() => setSelected(val)}
                                        />
                                        <Divider />
                                    </>
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </Drawer>
            </Hidden>
            {/* open drawer */}
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.bottomBarTitle}
                                    >
                                        {t("Manual_QR_Number")}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={() => setOpen(false)}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} p={2} margin={"6px"}>
                            <TextBox label={t("QR_Number")} value={qrCode} onChange={(e) => setQrCode(e.target.value)} />
                        </Grid>
                        <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.iconButtonStyle}
                                onClick={() => QrCodeApi(qrCode)}
                            >
                                <Typography className={classes.saveNUpdateButtonTextStyle}>
                                    {t("Verify")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.titleroot}
                            >
                                <Box flexGrow={1}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.bottomBarTitle}
                                    >
                                        {t("Manual_QR_Number")}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={() => setOpen(false)}>
                                        <CloseIcon color="secondary" />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} p={2} margin={"6px"}>
                            <TextBox label={t("QR_Number")} value={qrCode} onChange={(e) => setQrCode(e.target.value)} />
                        </Grid>
                        <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                            <Button
                                variant="contained"
                                fullWidth
                                className={classes.iconButtonStyle}
                                onClick={() => QrCodeApi(qrCode)}
                            >
                                <Typography className={classes.saveNUpdateButtonTextStyle}>
                                    {t("Verify")}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>

                </Drawer>
            </Hidden>
        </Container>
    </div>
}
export default withNamespaces("gatePassVerification")(GetPassVerification);
