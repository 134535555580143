import AddIcon from "@mui/icons-material/Add";
import { Box, Fab, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoaderSimmer, ServiceCard, TitleBar } from "../../components";
import { config } from "../../config";
import { GET_LIST, GET_PROPERTY_BY_ROLE } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "60px",
      right: "5%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "60px",
      left: "63%",
    },
  },
}));

export const SwiftInOutListVendor = (props) => {
  const classes = useStyles();
  const { state } = useLocation();
  const size = useWindowDimensions();
  const history = useHistory();
  const [list, getList] = React.useState([]);
  const search = useLocation().search;
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const Title = new URLSearchParams(search).get("title");
  const alert = React.useContext(AlertContext);
  const [load, setLoad] = React.useState(true);
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;


  const getListFromdashboard = (val, type, status, offset, limit) => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/security-dashboard/${state?.type}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let dataList = response?.data?.data?.map((val) => {
          return {
            id: val?.id,
            title: val?.vendorname ?? "-",
            check_out_time: val?.checkedouttime,
            subtitle1: val?.personname ?? "-",
            subtitle2: val?.referenceid ?? "-",
            img: "/images/domestic.svg",
          };
        });
        getList(dataList);
        setLoad(false)

      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getPassList = (val) => {
    const data = {
      query: GET_LIST,
      variables: {
        property_id: val,
        type: Title === "Service" ? "SP" : "VE",
        offset: 0,
        limit: 1000,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let dataList = response?.data?.data?.swift_in_out_log?.map((val) => {
          return {
            id: val?.id,
            title: val?.vendor_master?.name ?? "-",
            check_out_time: val?.check_out_time,
            subtitle1: val?.person_name ?? "-",
            subtitle2: val?.reference_id ?? "-",
            img: "/images/domestic.svg",
          };
        });
        getList(dataList);
        setLoad(false)
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_PROPERTY_BY_ROLE,
      variables: {
        user_profile_id: userProfileId,
        role_id: roleId,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        getPassList(
          response?.data?.data?.access_control?.map((val) => val?.property?.value)
        );
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  React.useEffect(() => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "",
    // });
    if (state?.type) {
      getListFromdashboard();
    } else {
      getPropertyId();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TitleBar
        text="Vendor Entry"
        // color="#5078E1"
        goBack={() => history.push(Routes.dashboard)}
      />
      {load ? (
        <LoaderSimmer count={10} />
      ) : (
        <div className={classes.root}>
          <Box display="flex" alignItems="center" p={1} position="sticky">
            <Box flexGrow={1}>
              <Typography className={classes.title}>
                Vendor ({list?.length})
              </Typography>
            </Box>
          </Box>
          <div
            style={{
              height: size.height - 120,
              overflow: "auto",
              marginRight: "-6px",
            }}
          >
            {list?.length > 0 &&
              list?.map((val) => <ServiceCard data={val} Title={Title} />)}

            <Fab
              color="primary"
              className={classes.fab}
              onClick={() => history.push(`${Routes.create}${"?create="}${"Vendor"}`)}
            >
              <AddIcon />
            </Fab>
          </div>
        </div>
      )}
    </>
  );
};
