import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",

    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#78B1FE",
    padding: "1px 8px",
    // borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "red",
    padding: "1px 8px",
  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "red",
    padding: "1px 8px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  rejected: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#EC903F",
    padding: "1px 8px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "122px",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down(321)]: {
      height: "142px",

    },
  },
  bold: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.secondary,
  },
  semiBold: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,

  },
  bottomText: {
    bottom: 0,
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px"
  },
  flag: {
    backgroundColor: "#5078E1",
    padding: "2px 8px",
    position: "absolute",
    top: "4px",
    right: "-10px",
    borderRadius: "4px",
    boxShadow: '0px 3px 6px #00000029'
  },
  flagChild: {
    height: "10px",
    width: "11px",
    backgroundColor: "#5078E1",
    bottom: "-8px",
    right: "-8px",
    position: "absolute",
    borderBottomRightRadius: '195px'
  },
  flagName: {
    color: "white",
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",

  },
}));

export const SwiftCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container className={classes.card}
        onClick={() =>
          history.push(Routes.swiftInOutDetails + "?passId=" + props?.data?.id)
        }
      >

        <Grid item xs={4} md={3}>
          <Box style={{ position: "relative" }}>
            {props?.data?.img ?
              <img src={props?.data?.img} alt="" className={classes.avatar} /> :
              <Avatar className={classes.avatar} variant="square" />
            }
            <Box style={{ position: "absolute" }} className={classes.bottomText1}>
              {props?.data?.status === "Pending" && (
                <Typography
                  variant="subtitle2"
                  noWrap
                  className={classes.progress}
                >
                  In Progress
                </Typography>
              )}
              {props?.data?.status === "Approved" && (
                <Typography
                  variant="subtitle2"
                  className={classes.approved}
                >
                  Approved
                </Typography>
              )}
              {props?.data?.status === "Rejected" && (
                <Typography
                  variant="subtitle2"
                  className={classes.rejected}
                >
                  Rejected
                </Typography>
              )}
              {props?.data?.status === "Cancelled" && (
                <Typography variant="subtitle2" className={classes.delete}>
                  Cancelled
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} md={9} alignItems="center" sx={{ padding: "12px" }}>
          <Typography noWrap className={classes.title}>
            {props?.data?.subtitle2}
          </Typography>
          <Stack direction="row" marginTop="6px" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
            <Typography

              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {moment(props?.data?.check_in_time)
                .tz(moment.tz.guess())
                .format("DD MMM YY")}
            </Typography>
            {
              props?.data?.check_out_time &&
              <Typography
                variant="subtitle2"
                className={classes.sub}
                noWrap
              >
                {moment(props?.data?.check_out_time)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}
              </Typography>
            }

          </Stack>
          <Stack direction="row" marginTop="6px" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
            <Typography

              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.data?.property}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.data?.unit}
            </Typography>
          </Stack>

          <Stack direction="row" marginTop="6px" spacing={1}>
            <Typography

              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.data?.person_name}
            </Typography>
          </Stack>

        </Grid>
      </Grid>
      <Box className={classes.flag}>
        <Box position={"relative"}>
          <Typography className={classes.flagName} noWrap>
            {props?.data?.title}
          </Typography>
          <Box className={classes.flagChild} />
        </Box>
      </Box>

    </Box>
  );
};
