
import CloseIcon from "@mui/icons-material/Close";
import {
    Avatar,
    Box,
    Button, Dialog, Divider, Drawer, Grid,
    Hidden, IconButton,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { SlideButton, TextBox } from '../../components';
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { COLLECTED_AMOUNT, UPSERT_SWIFT_IN_OUT_LOG } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { ViewDetails } from "../../screens/gateEntry/viewDetails";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, visitorTypes } from "../../utils";
import ImageViewer from "react-simple-image-viewer";

const useStyles = makeStyles((theme) => ({

    rootCard: {
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow: "0px 3px 16px #00000014",
        border: "1px solid #E4E8EE"
    },
    img: {
        // borderRadius: "8px",
        // border: "1px solid #E4E8EE",
        // padding: "2px 8px",
    },

    title: {
        fontSize: "16px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: "#4E5A6B",
    },
    title2: {
        fontSize: "14px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        color: theme.typography.color.primary,
    },
    sub: {
        fontSize: "12px",
        color: theme.palette.primary.main,
        cursor: "pointer"
    },

    border: {
        borderBottom: "2px solid #E4E8EE",
    },
    heading: {
        fontSize: "12px",
        color: theme.typography.color.Tertiary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        marginTop: "10px",
    },
    heading2: {
        fontSize: "14px",
        color: theme.typography.color.Tertiary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
    heading3: {
        fontSize: "14px",
        color: theme.palette.success.main,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
    name: {
        fontSize: "14px",
        color: theme.typography.color.primary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
    span: {
        backgroundColor: "#5078E11E",
        color: "#98A0AC",
        fontSize: "10px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        marginLeft: "6px",
        padding: "4px",
        borderRadius: "4px"

    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "8px",
    },
    bottomBarTitle: {
        fontSize: "18px",
        fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
        color: theme.typography.color.primary,
    },
    iconButtonStyle: {
        height: "44px",

        padding: "12px 16px 12px 16px",
        borderRadius: "8px",
        color: "#5078E1",
        background: "#5078E1",
        backgroundColor: "#5078E1",
        boxShadow: "0px 2px 6px #00000021",
    },
    saveNUpdateButtonTextStyle: {
        fontSize: "14px",
        color: "#FFFFFF",
        fontWeight: "bold",
    },
    saveNUpdateButtonContainerStyle: {
        padding: "16px",
        background: "white",
        border: "1px solid #E4E8EE",
        boxShadow: "0px -7px 20px #0717410D",
        // position: "fixed",
        width: "-webkit-fill-available",
    },
    sucess: {
        backgroundColor: theme.palette.success.main,
        "&:hover": {
            backgroundColor: theme.palette.success.main,
        }
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        // padding: 2,
        "&:hover": {
            backgroundColor: theme.palette.warning.main,
        }
    },

}));

export const DetailsCard = (props) => {
    const classes = useStyles(props);
    const [drawer, setDrawer] = React.useState(false);
    const [drawerAmount, setDrawerAmount] = React.useState(false);
    const [showBill, setShowBill] = React.useState(props?.data?.is_unit_bill);
    const [amount, setAmount] = React.useState([])
    const alert = React.useContext(AlertContext);
    const [details, setDetails] = React.useState({})
    const [collectedAmount, setCollectedAmount] = React.useState("")
    const [swiftId, setSwiftId] = React.useState("")
    const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
    const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;

    const openDrawer = (val) => {
        setDetails(val)
        props?.ReloadEnum()
        setDrawer(true)
    }
    const openDrawerAmount = () => {
        setDrawerAmount(true)
    }

    const ChargesApi = () => {
        const data = {
            tenantId: `${config.tenantid}`,
            request_id: props?.data?.id
        };
        NetworkCall(
            `${config.api_url}/security/charges`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response.status === 200) {
                    setAmount(response?.data?.data[0])
                    props?.Reload()
                }
            })
            .catch((error) => {
                console.log(error);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something Went Wrong",
                });
            });

    }

    React.useEffect(() => {
        if (props?.data?.actual_out) {
            ChargesApi()
        }
        // eslint-disable-next-line
    }, [props?.data?.actual_out])

    const CollectedAmountApi = () => {
        if (collectedAmount !== "") {
            const payload = {
                query: COLLECTED_AMOUNT,
                variables: {
                    data: {
                        actual_payment: parseFloat(collectedAmount),
                        balance: parseFloat(amount?.payment_charges - collectedAmount).toFixed(1),
                        payment_status: 'Collected'
                    },
                    request_id: props?.data?.id
                }
            }
            NetworkCall(
                `${config.graphql_url}`,
                NetWorkCallMethods.post,
                payload,
                null,
                true,
                false
            )
                .then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "Amount collected successfully",
                    });
                    setDrawerAmount(false)
                    props?.Reload()
                })
                .catch((err) => {
                    console.log(err);
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something Went Wrong",
                    });
                });
        }
    }
    const BillToAmountApi = () => {
        const payload = {
            query: COLLECTED_AMOUNT,
            variables: {
                data: {
                    payment_status: 'Pending',
                    is_unit_bill: true
                },
                request_id: props?.data?.id
            }
        }
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                setShowBill(true)
                props?.Reload()
            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something Went Wrong",
                });
            });
    }


    //domestic checkin out

    const checkIn = () => {
        let currentDate = new Date().toISOString()
        const data = {
            query: UPSERT_SWIFT_IN_OUT_LOG("insert").loc?.source?.body,
            variables: {
                "insertPayload": [
                    {
                        access_gate_id: Number(props?.gateid),
                        check_in_by: userProfileId,
                        check_in_time: currentDate,
                        created_at: currentDate,
                        created_by: userProfileId,
                        helper_id: props?.data?.domestic_helper_units?.[0]?.helper?.id,
                        is_active: true,
                        person_name: props?.data?.domestic_helper_units?.[0]?.helper?.name,
                        property_id: props?.propertyId,
                        visitor_type: visitorTypes?.domesticHelper,
                        client: clientId
                    }
                ]
            }
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.data) {
                    setSwiftId(response?.data?.data?.swift_in_out_log[0]?.id)
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: "checked In",
                    });
                    props?.Reload()
                }
            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something Went Wrong",
                });
            });
    }
    const checkOut = () => {
        let currentDate = new Date().toISOString()
        const data5 = {
            query: UPSERT_SWIFT_IN_OUT_LOG("update").loc?.source?.body,
            variables: {
                swiftInOutID: props?.data?.swift_in_out_log?.[0]?.id ? props?.data?.swift_in_out_log?.[0]?.id : swiftId,
                updatePayload: {
                    check_out_by: userProfileId,
                    check_out_time: currentDate,
                    updated_by: userProfileId,
                    updated_at: currentDate,
                    client: clientId

                }
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            data5,
            null,
            true,
            false
        )
            .then((response) => {
                props?.domesticCheckout(response?.data?.data?.swift_in_out_log?.[0]?.id)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.warning,
                    msg: "checked Out",
                });
                props?.Reload()
            })
            .catch((err) => {
                console.log(err);
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something Went Wrong",
                });
            });
    }
    const disableButton = ((new Date() <= new Date(props?.parentData?.request_from)) || (new Date() >= new Date(props?.parentData?.request_to))) ? true : false
    return (
        <>
            <div className={classes.rootCard}>
                {props?.data?.domestic_helper_units &&
                    <>
                        <Box p={"12px"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.heading}>
                                        APPROVED UNITS AS DOMESTIC HELPER
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.title2}>
                                        {props?.data?.domestic_helper_units.map((item) => item?.unit?.name).join(',')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} margin={"10px 0px"}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.heading}>
                                        Checked in
                                    </Typography>
                                    {props?.data?.swift_in_out_log &&
                                        <Typography variant="subtitle2" className={classes.title}>
                                            {props?.data?.swift_in_out_log?.[0]?.check_in_time &&
                                                <>
                                                    {props?.data?.swift_in_out_log?.[0]?.check_in_by?.first_name && "By " + props?.data?.swift_in_out_log?.[0]?.check_in_by?.first_name + ","}&nbsp;
                                                    {props?.data?.swift_in_out_log?.[0]?.check_in_time ? moment(props?.data?.swift_in_out_log?.[0]?.check_in_time).tz(moment.tz.guess())
                                                        .format("DD MMM YY  hh:mm:a") : "-"}
                                                </>
                                            }
                                        </Typography>

                                    }

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.heading}>
                                        Checked out
                                    </Typography>
                                    {props?.data?.swift_in_out_log &&
                                        <Typography variant="subtitle2" className={classes.title}>
                                            {props?.data?.swift_in_out_log?.[0]?.check_in_time &&
                                                <>
                                                    {props?.data?.swift_in_out_log?.[0]?.check_out_by?.first_name && "By " + props?.data?.swift_in_out_log?.[0]?.check_out_by?.first_name + ","}&nbsp;
                                                    {props?.data?.swift_in_out_log?.[0]?.check_out_time ? moment(props?.data?.swift_in_out_log?.[0]?.check_out_time).tz(moment.tz.guess())
                                                        .format("DD MMM YY  hh:mm:a") : "-"}
                                                </>
                                            }
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    <SlideButton
                                        mainText={`Swipe to ${(props?.data?.swift_in_out_log?.[0]?.check_in_time === null || props?.data?.swift_in_out_log?.[0]?.check_out_time !== null) ? " Check In" : "Check Out"}`}
                                        overlayText={(props?.data?.swift_in_out_log?.[0]?.check_in_time === null || props?.data?.swift_in_out_log?.[0]?.check_out_time !== null) ? "Checked In" : "Checked Out"}
                                        classList={(props?.data?.swift_in_out_log?.[0]?.check_in_time === null || props?.data?.swift_in_out_log?.[0]?.check_out_time !== null) ? "my-class1" : "my-class2"}
                                        caretClassList={(props?.data?.swift_in_out_log?.[0]?.check_in_time === null || props?.data?.swift_in_out_log?.[0]?.check_out_time !== null) ? "my-caret-class1" : "my-caret-class2"}
                                        overlayClassList={(props?.data?.swift_in_out_log?.[0]?.check_in_time === null || props?.data?.swift_in_out_log?.[0]?.check_out_time !== null) ? "my-overlay-class1" : "my-overlay-class2"}
                                        onSlideDone={props?.data?.swift_in_out_log?.[0]?.check_in_time && props?.data?.swift_in_out_log?.[0]?.check_out_time ? checkIn : props?.data?.swift_in_out_log?.[0]?.check_in_time ? checkOut : checkIn}
                                    />
                                    {/* <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={props?.data?.swift_in_out_log?.[0]?.check_in_time && props?.data?.swift_in_out_log?.[0]?.check_out_time ? checkIn : props?.data?.swift_in_out_log?.[0]?.check_in_time ? checkOut : checkIn}
                                        className={(props?.data?.swift_in_out_log?.[0]?.check_in_time === null || props?.data?.swift_in_out_log?.[0]?.check_out_time !== null) ? classes.sucess : classes?.warning}
                                        // disabled={props?.data?.swift_in_out_log?.[0]?.check_in_time && props?.data?.swift_in_out_log?.[0]?.check_out_time}
                                        size="large"
                                    >
                                        {(props?.data?.swift_in_out_log?.[0]?.check_in_time === null || props?.data?.swift_in_out_log?.[0]?.check_out_time !== null) ? " Check In" : "Check Out"}
                                    </Button> */}
                                </Grid>
                            </Grid>
                        </Box>

                    </>
                }
                {!props?.data?.domestic_helper_units &&
                    <>
                        <Box display="flex" alignItems="center" p={"12px"} className={classes.border} >
                            <Box className={classes.img}>

                                {props?.type === "WGR" &&
                                    <>
                                        {props?.data?.visitor_image ?
                                            <Avatar src={props?.data?.visitor_image ? props?.data?.visitor_image : ""}
                                                onClick={() => props?.openImageViewer([props?.data?.visitor_image])}
                                            >
                                            </Avatar> : <Avatar></Avatar>
                                        }
                                    </>
                                }

                                {props?.type === "VGR" &&
                                    <>
                                        {props?.data?.visitor_image ?
                                            <Avatar src={props?.data?.visitor_image ? props?.data?.visitor_image : ""}
                                                onClick={() => props?.openImageViewer([props?.data?.visitor_image])}
                                            >
                                            </Avatar> : <Avatar></Avatar>
                                        }
                                    </>
                                }
                                {props?.type === "PSR" &&
                                    <>
                                        {props?.data?.vehicle_image ?
                                            <Avatar src={props?.data?.vehicle_image ? props?.data?.vehicle_image : ""}
                                                onClick={() => props?.openImageViewer([props?.data?.vehicle_image])}
                                            ></Avatar> :
                                            <Avatar></Avatar>
                                        }
                                    </>

                                }
                                {props?.isViewerOpen && (
                                    <ImageViewer
                                        src={props?.currentImage}
                                        currentIndex={0}
                                        onClose={props?.closeImageViewer}
                                        disableScroll={false}
                                        backgroundStyle={{
                                            backgroundColor: "rgba(0,0,0,0.9)",
                                        }}
                                        closeOnClickOutside={true}
                                    />
                                )}
                            </Box>
                            <Box marginLeft={"12px"} >
                                <Typography variant="subtitle2" className={classes.name}>
                                    {props?.data?.name ? props?.data?.name : props?.data?.vechicle_number}
                                    {props?.data?.vechicle_type && <span className={classes.span}>{props?.data?.vechicle_type}</span>}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.sub}>
                                    <div onClick={() => openDrawer(props?.data)}>
                                        {props?.data?.vechicle_type ? "Update Details" : "View Details"}
                                    </div>
                                </Typography>
                            </Box>
                        </Box>
                        <Box p={"12px"}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.heading}>
                                        Check in
                                    </Typography>
                                    {props?.data?.check_in_time &&
                                        <Typography variant="subtitle2" className={classes.title}>
                                            {props?.data?.check_in_by && "By " + props?.data?.check_in_by + ","}&nbsp;
                                            {props?.data?.check_in_time ? moment(props?.data?.check_in_time).tz(moment.tz.guess())
                                                .format("DD MMM YY  hh:mm:a") : "-"}
                                        </Typography>
                                    }
                                    {props?.data?.actual_in &&
                                        <Typography variant="subtitle2" className={classes.title}>
                                            {props?.data?.parking_check_in_by && "By " + props?.data?.parking_check_in_by + ","}&nbsp;
                                            {props?.data?.actual_in ? moment(props?.data?.actual_in).tz(moment.tz.guess())
                                                .format("DD MMM YY  hh:mm:a") : "-"}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.heading}>
                                        Check out
                                    </Typography>
                                    {props?.data?.check_out_time &&
                                        <Typography variant="subtitle2" className={classes.title}>
                                            {props?.data?.check_out_by && "By " + props?.data?.check_out_by + ","}&nbsp;
                                            {props?.data?.check_out_time ? moment(props?.data?.check_out_time).tz(moment.tz.guess())
                                                .format("DD MMM YY  hh:mm:a") : "-"}
                                        </Typography>
                                    }
                                    {props?.data?.actual_out &&
                                        <Typography variant="subtitle2" className={classes.title}>
                                            {props?.data?.parking_check_out_by && "By " + props?.data?.parking_check_out_by + ","}&nbsp;
                                            {props?.data?.actual_out ? moment(props?.data?.actual_out).tz(moment.tz.guess())
                                                .format("DD MMM YY  hh:mm:a") : "-"}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <br />
                                    {props?.data?.vechicle_type ?
                                        <SlideButton
                                            mainText={`Swipe to ${props?.data?.actual_in === null && props?.data?.actual_out === null ? " Check In" : "Check Out"}`}
                                            overlayText={props?.data?.actual_in === null && props?.data?.actual_out === null ? "Checked In" : "Checked Out"}
                                            classList={props?.data?.actual_in === null && props?.data?.actual_out === null ? "my-class1" : "my-class2"}
                                            caretClassList={props?.data?.actual_in === null && props?.data?.actual_out === null ? "my-caret-class1" : "my-caret-class2"}
                                            overlayClassList={props?.data?.actual_in === null && props?.data?.actual_out === null ? "my-overlay-class1" : "my-overlay-class2"}
                                            onSlideDone={() => openDrawer(props?.data)}
                                            disabled={props?.data?.actual_in && props?.data?.actual_out}
                                        />
                                        // <Button
                                        //     fullWidth
                                        //     variant="contained"
                                        //     onClick={() => openDrawer(props?.data)}
                                        //     color={props?.data?.actual_in === null && props?.data?.actual_out === null ? "success" : "warning"}
                                        //     disabled={props?.data?.actual_in && props?.data?.actual_out}
                                        //     size="large"
                                        // >
                                        //     {props?.data?.actual_in === null && props?.data?.actual_out === null ? " Check In" : "Check Out"}
                                        // </Button>
                                        :
                                        <SlideButton
                                            mainText={`Swipe to ${(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? " Check In" : "Check Out"}`}
                                            overlayText={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "Checked In" : "Checked Out"}
                                            classList={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "my-class1" : "my-class2"}
                                            caretClassList={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "my-caret-class1" : "my-caret-class2"}
                                            overlayClassList={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "my-overlay-class1" : "my-overlay-class2"}
                                            onSlideDone={() => openDrawer(props?.data)}
                                            disabled={disableButton}
                                        />
                                        // <Button
                                        //     fullWidth
                                        //     variant="contained"
                                        //     onClick={() => openDrawer(props?.data)}
                                        //     color={(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? "success" : "warning"}
                                        //     size="large"
                                        //     disabled={disableButton}

                                        // >
                                        //     {(props?.data?.check_in_time === null || props?.data?.check_out_time !== null) ? " Check In" : "Check Out"}
                                        // </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    </>}
            </div >
            {props?.data?.actual_out &&
                <>
                    <br />
                    <div className={classes.rootCard}>
                        <Box p={"12px"}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" className={classes.heading}>
                                        ALLOCATED PARKING TIME
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.title}>
                                        {props?.data?.approved_hours ? props?.data?.approved_hours + `${" Hrs"}` : " - "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" className={classes.heading}>
                                        ACTUAL PARKING TIME
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.title}>
                                        {props?.data?.actual_hours ? props?.data?.actual_hours + `${" Hrs"}` : " 0 "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} margin="10px 0px">
                                    <Divider />
                                </Grid>
                                <Grid item xs={6} marginBottom="5px">
                                    <Typography variant="subtitle2" className={classes.heading2}>
                                        Chargers to be collected
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} textAlign="right" marginBottom="5px">
                                    <Typography variant="subtitle2" className={classes.title2}>
                                        {amount?.symbol}&nbsp;{amount?.payment_charges ? amount?.payment_charges : " - "}
                                    </Typography>
                                </Grid>
                                {showBill &&
                                    <>
                                        <Grid item xs={6} marginBottom="5px">
                                            <Typography variant="subtitle2" className={classes.heading3}>
                                                Bill to the unit
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} textAlign="right" marginBottom="5px">
                                            <Typography variant="subtitle2" className={classes.heading3}>
                                                {amount?.symbol}&nbsp;{amount?.payment_charges ? amount?.payment_charges : " - "}
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={5.5} >
                                    <Button variant="outlined"
                                        fullWidth
                                        // padding="0px 8px"
                                        style={{ borderRadius: "8px" }}
                                        onClick={BillToAmountApi}
                                        disabled={showBill ? showBill : props?.data?.actual_payment}
                                    >
                                        Bill To The Unit
                                    </Button>
                                </Grid>
                                <Grid item xs={1} />
                                <Grid item xs={5.5}>
                                    <Button variant="contained"
                                        fullWidth
                                        style={{ borderRadius: "8px", padding: "6px 12px" }}
                                        onClick={openDrawerAmount}
                                        disabled={showBill ? showBill : props?.data?.actual_payment}
                                    >
                                        Collected amount
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Hidden smDown>
                            <Dialog
                                className={classes.dialog}
                                maxWidth="sm"
                                fullWidth
                                open={drawerAmount}
                                onClose={() => setDrawerAmount(false)}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            className={classes.titleroot}
                                        >
                                            <Box flexGrow={1}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className={classes.bottomBarTitle}
                                                >
                                                    Collect Amount
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton size="small" onClick={() => setDrawerAmount(false)}>
                                                    <CloseIcon color="secondary" />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} p={2} margin={"6px"}>
                                        <TextBox label="Collected Amount" value={collectedAmount} onChange={(e) => setCollectedAmount(e.target.value)} />
                                    </Grid>
                                    <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            className={classes.iconButtonStyle}
                                            onClick={CollectedAmountApi}
                                        >
                                            <Typography className={classes.saveNUpdateButtonTextStyle}>
                                                {"Collect And Close"}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </Hidden>
                        <Hidden smUp>
                            <Drawer
                                className={classes.drawer}
                                anchor="bottom"
                                open={drawerAmount}
                                onClose={() => setDrawerAmount(false)}
                            >
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            className={classes.titleroot}
                                        >
                                            <Box flexGrow={1}>
                                                <Typography
                                                    variant="subtitle2"
                                                    className={classes.bottomBarTitle}
                                                >
                                                    Collect Amount
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <IconButton size="small" onClick={() => setDrawerAmount(false)}>
                                                    <CloseIcon color="secondary" />
                                                </IconButton>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} p={2} margin={"6px"}>
                                        <TextBox label="Collected Amount" value={collectedAmount} onChange={(e) => setCollectedAmount(e.target.value)} />
                                    </Grid>
                                    <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            className={classes.iconButtonStyle}
                                            onClick={CollectedAmountApi}
                                        >
                                            <Typography className={classes.saveNUpdateButtonTextStyle}>
                                                {"Collect And Close"}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Drawer>
                        </Hidden>
                    </div>
                </>
            }

            {/* drawer */}
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <ViewDetails
                        onClose={() => setDrawer(false)}
                        data={details}
                        type={props?.type}
                        Reload={props?.Reload}
                        proofData={props?.proofData}
                        statusKey={props?.statusKey}
                        disabled={disableButton}
                    />
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    anchor="bottom"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <ViewDetails
                        onClose={() => setDrawer(false)}
                        data={details}
                        type={props?.type}
                        Reload={props?.Reload}
                        proofData={props?.proofData}
                        statusKey={props?.statusKey}
                        disabled={disableButton}
                    />
                </Drawer>
            </Hidden>
        </>
    )
}