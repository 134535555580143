import { Button, Container, Grid, Typography , Box} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Message, NetWorkCallMethods } from "../../utils";
import { PasswordInfo } from "../../components/passwordInfo";

const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: theme.palette.primary.main,
    height: "40vh",
    backgroundImage: `url("images/bgNew.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100% 25%",

  },
  content: {
    width: "100%",
    padding: 20,
    overflow: "auto"

  },
  text: {
    fontSize: "24px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "opx 6px 10px #00000014",
    padding: "12px"
  },
  img: {
    width: "auto",
    height: "21.9px",
    // marginTop: "5px"
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "10px",
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  btnCard:{
    position:"sticky",
    bottom:"0",
    padding:"16px",
    backgroundColor:"#fff"
  }
}));

const InitialState = {
  email: "",
  enterPassword: "",
  confirmPassword: "",
  error: {
    email: "",
    enterPassword: "",
    confirmPassword: "",
  },
};

export const SetupAccount = () => {
  const classes = useStyles();
  const history = useHistory();
  const [account, setAccount] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);

  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  // const decoded =jwt_decode(welcomePageAuthToken);
  const [decoded, setDecoded] = React.useState(null);
  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);

  const updateState = (key, value) => {
    let error = account.error;
    error[key] = "";
    setAccount({ ...account, [key]: value, error });
  };
  const isIamValideToCreateAccount = () => {
    let isValid = true;
    let error = account.error;

    const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    const is_password_strong = is_password_strong_regex.test(account.enterPassword)

    //Checking Email
    // if (account.email.length === 0) {
    //     isValid = false;
    //     error.email = Message.English.requiredMessage('Email')
    // }

    //Checking enterPassword
    if (account.enterPassword.length === 0) {
      isValid = false;
      error.enterPassword = Message.English.requiredMessage("Password");
    } else if (!Boolean(is_password_strong)) {
      isValid = false;
      error.enterPassword = "Password is not stong";
    }

    //Checking confirmPassword
    if (account.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword =
        Message.English.requiredMessage("confirm Password");
    }
    if (account.confirmPassword.length > 0) {
      if (
        account.enterPassword.length > 0 &&
        account.enterPassword !== account.confirmPassword
      ) {
        isValid = false;
        error.confirmPassword = "Password does not Match";
      }
    }
    if (
      account.email &&
      account.enterPassword !== "" &&
      account.confirmPassword !== ""
    ) {
      if (account.enterPassword === account.confirmPassword) {
        isValid = true;
      }
    }

    setAccount({ ...account, error });
    return isValid;
  };

  const onSendAccountBtnClicked = () => {
    if (isIamValideToCreateAccount()) {
      const payload = {
        password: account.confirmPassword,
      };
      NetworkCall(
        `${config.auth_api_url}/auth/updatepassword/?token=${welcomePageAuthToken}`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 201) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Password created successfully",
            });
            localStorage.clear();
            history.push(
              Routes.login + "?welcomeAuthToken=" + welcomePageAuthToken
            );
          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing went wrong",
          });
        });
    } else {
      return false;
    }
  };


  return (
    <>
      <Container maxWidth="sm" style={{ padding: 0 , position:"relative" , height:"100vh" }}>
        <Grid container>
          <Grid item xs={12} className={classes.backgroundImage} />
          <Grid container className={classes.content}>
            <Grid item xs={12}>
              <img
                src="images/casagrandLogo.png"
                alt="casagrandLogo"
                className={classes.img}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>
                Set up your account details below
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextBox
                label={Message.English.createAccount.email.label}
                placeholder={Message.English.createAccount.email.placeholder}
                isError={account.error.email.length > 0}
                errorMessage={account.error.email}
                value={decoded && decoded.email_id ? decoded.email_id : ""}
                isReadonly
                disabled
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
              <TextBox
                value={account.enterPassword}
                type="password"
                onChange={(e) => updateState("enterPassword", e.target.value)}
                label={Message.English.createAccount.enterPassword.label}
                placeholder={
                  Message.English.createAccount.enterPassword.placeholder
                }
                isError={account.error.enterPassword.length > 0}
                errorMessage={account.error.enterPassword}
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
              <TextBox
                value={account.confirmPassword}
                type="password"
                onChange={(e) => updateState("confirmPassword", e.target.value)}
                label={Message.English.createAccount.confirmPassword.label}
                placeholder={
                  Message.English.createAccount.confirmPassword.placeholder
                }
                isError={account.error.confirmPassword.length > 0}
                errorMessage={account.error.confirmPassword}
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
            <PasswordInfo />
            </Grid>
          </Grid>
        </Grid>
        <Box height={'50px'}/>
        <Box className={classes.btnCard}>
        <Button
                fullWidth
                variant="contained"
                className={classes.btn}
                onClick={onSendAccountBtnClicked}
              >
                Confirm
              </Button>
        </Box>
      </Container>
    </>
  );
};
