import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MobileNumberInputComponent,
  TextBox,
  TitleBar
} from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, enumSelect, enumTypes, keyMapSource, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { VisiterCard } from "./visiterCard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    margin: "12px 10px 8px 14px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },
  title: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    marginTop: "10px",
  },
  subtitle: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    paddingLeft: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 8px 8px 14px",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  userimg: {
    borderRadius: "50%",
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
  stack: {
    backgroundColor: "#F5F7FA",
    margin: "12px",
  },
  stack1: {
    borderRight: "1px solid #E4E8EE",
  },
  preview: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.Tertiary,
    textAlign: "center",
    marginBottom: "8px",
  },
  input: {
    marginTop: "10px",
  },
  border: {
    borderBottom: "2px solid #E4E8EE",
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
}));
const InitialState = {
  name: "",
  mobile: "",
  mail: "",
  idType: "",
  idno: "",
};
export const GatePassDetails = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const [viewDetail, setVieDetail] = React.useState({ ...InitialState });
  const [drawer, setDrawer] = React.useState(false);
  const [detail, setDetail] = React.useState([]);
  const searchURL = useLocation().search;
  const alert = React.useContext(AlertContext);
  const passId = new URLSearchParams(searchURL).get("passId");
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState("");
  const [requestType, setRequestType] = React.useState();


  //openImageViewer
  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  //closeImageViewer
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const getPassDetails = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_id: passId,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetail(response?.data?.data?.[0]);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  React.useEffect(() => {
    getPassDetails();
    // eslint-disable-next-line
  }, []);

  const viewDetails = (data) => {
    setDrawer(true);
    const InitialState = {
      name: data?.name,
      mobile: {
        mobile: data?.mobile_no,
        mobile_code: data?.mobile_country_code,
      },
      mail: data?.email_id,
      idType: data?.id_type,
      idno: data?.identification_no,
      previewImage: data?.visitor_image,
      verifiedImage: data?.guard_image,
    };
    setVieDetail(InitialState);
  };

  const getEnum = async () => {
    const result = await enumSelect([enumTypes?.security_request_type])
    let mapResult = keyMapSource({ enumType: result?.security_request_type })
    setRequestType(mapResult)
  }
  const renderForm = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              View Visitor Details
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Box>
        </Box>
        <div
          style={{
            padding: "12px",
            height: size.height - 200,
            overflow: "scroll",
          }}
        >
          <Box
            p={1}
            className={classes.stack}
            display="flex"
            justifyContent="space-around"
          >
            <Box p={1}>
              <Typography className={classes.preview}>PREVIEW</Typography>
              <Avatar sx={{ width: 90, height: 90 }}>

                <Avatar src={viewDetail?.previewImage ?? null} className={classes.userimg} />

              </Avatar>
            </Box>
            <Box className={classes.stack1} />
            <Box p={1} className={classes.stack2}>
              <Typography className={classes.preview}>VERIFIED</Typography>
              <Avatar sx={{ width: 90, height: 90 }}>

                <Avatar src={viewDetail?.verifiedImage ?? null} className={classes.userimg} />

              </Avatar>
            </Box>
          </Box>
          <Box p={1.5}>
            <Typography className={classes.title}>VISITOR DETAIL</Typography>
            <div className={classes.input}>
              <TextBox
                label="Visitor Name"
                placeholder="Visitor Name"
                value={viewDetail?.name}
                disabled
              />
            </div>
            <div className={classes.input}>
              <MobileNumberInputComponent
                label="Phone Number"
                placeholder="Phone Number"
                value={viewDetail?.mobile}
                disabled
              />
            </div>
            <div className={classes.input}>
              <TextBox
                label="Mail ID"
                placeholder="Mail ID"
                value={viewDetail?.mail}
                disabled
              />
            </div>
          </Box>
          <Box className={classes.border} />
          <Box p={1.5}>
            <Typography className={classes.title}>VISITOR PROOF</Typography>
            <div className={classes.input}>
              <TextBox
                label="ID Proof"
                placeholder="ID Proof"
                value={viewDetail?.idType}
                disabled
              />
            </div>

            <div className={classes.input}>
              <TextBox
                label="ID Number"
                placeholder="ID Number"
                value={viewDetail?.idno}
                disabled
              />
            </div>
          </Box>
        </div>
        <div style={{ padding: "12px", borderTop: "2px solid #E4E8EE" }}>
          <Button
            className={classes.submitbtn}
            variant="contained"
            fullWidth
            onClick={() => setDrawer(false)}
          >
            Close
          </Button>
        </div>
      </>
    );
  };
  React.useEffect(() => {
    getEnum()
    // eslint-disable-next-line

  }, [])
  return (
    <Container maxWidth="sm" sx={{ padding: "0px", overflow: "hidden" }}>
      <TitleBar
        text="Visitor Gate Pass"
        // color="#5078E1"
        goBack={() => history.goBack()}
      />
      <div
        className={classes.root}
        style={{
          height: size.height - 60,
          overflow: "scroll",
        }}
      >
        <div className={classes.cardContent}>
          <Box display="flex" alignItems="center">
            <Box className={classes.img}>
              <img
                src="/images/pass.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginLeft="14px">
              <Typography className={classes.title}>
                {detail?.request_type === requestType?.typeA && "Visitor Gate Pass"}
                {detail?.request_type === requestType?.typeB && "Worker Gate Pass"}
              </Typography>
              <Typography className={classes.sub}>
                {detail?.request_no}
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                UNIT
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {detail?.unit?.[0]?.name}
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                GATE ENTRY
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {detail?.gate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                VISIT START & END DATE
              </Typography>
              <Typography variant="subtitle2" className={classes.title} noWrap>
                {moment(detail?.request_from)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}
                -{/* .format("DD MMM YY hh:mm:a")} */}
                {/* <Box height="2px" /> */}
                {moment(detail?.request_to)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY ")}
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                NO OF VISITOR
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {detail?.visitors?.length}
              </Typography>
            </Grid>
          </Grid>
        </div>
        {detail?.visitors?.length > 0 && (
          <div style={{ margin: "14px 8px 8px 14px" }}>
            <Typography className={classes.subtitle}>
              VISITOR DETAILS
            </Typography>
            <Box height="10px" />
            {detail?.visitors?.map((val) => (
              <>
                <VisiterCard
                  data={val}
                  onClick={viewDetails}
                  closeImageViewer={closeImageViewer}
                  openImageViewer={openImageViewer}
                  isViewerOpen={isViewerOpen}
                  currentImage={currentImage}
                />
                <Box height="10px" />
              </>
            ))}
          </div>
        )}
      </div>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
