import React from "react";
import { GatePassDetails } from "./passDetails";

class GatePassDetailsParent extends React.Component {
  render() {
    return <GatePassDetails />;
  }
}

export default GatePassDetailsParent;
