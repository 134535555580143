import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { HomepageCard, QuickCard, TopNavBars } from "../../components";
import { DashboardSimmer } from "../../components/simmer/dashboardSimmer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
// import { WelcomeBanner } from "./component";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    overflow: "hidden",
  },
  msg: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    color: theme.typography.color.primary,
  },

  profileImg: {
    height: "55px",
    width: "55px",
    borderRadius: "50%",
    border: "2px solid #091B29",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
    bottom: "0px",
    backgroundColor: "#091B29",
    borderRadius: "7px",
    color: "white",
    left: "0px",
    right: "0px",
    fontSize: "12px",
    textAlign: "center",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  username: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  invoice: {
    backgroundColor: "white",
    padding: "14px",
    borderRadius: theme.palette.borderRadius,
    marginTop: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  title: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
  },
  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.palette.primary.main,
  },
  unselectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.typography.color.Tertiary,
  },
  menuText: {
    fontSize: "14px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  menuItem: {
    position: "absolute",
    padding: 0,
    background: theme.palette.common.white,
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      right: "5%",
    },
    [theme.breakpoints.up("sm")]: {
      left: "58%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
  box: {
    border: "1px solid #E4E8EE",
    padding: "8px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  text: {
    fontSize: "11px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.palette.secondary.main,
  },
}));
const Dashboard = (props) => {
  const { t } = props;
  const classes = useStyles();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const history = useHistory();
  // const { state } = useLocation()
  const hour = new Date().getHours();
  const [decoded, setDecoded] = React.useState(null);
  const [data, setData] = React.useState({});
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [drawer, setDrawer] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  // // open dialog
  // const [open, setOpen] = React.useState(state ?? false)
  // // close dialog
  // const closeDrawer = () => {
  //   setOpen(!open)
  // }

  const getDashboardDetails = () => {
    if (localStorage.getItem(LocalStorageKeys.authToken)) {
      const tempDecoded = jwt_decode(authToken);
      setDecoded(tempDecoded);
      const data = {
        tenantId: `${config.tenantid}`,
        userId: tempDecoded.id,
      };
      NetworkCall(
        `${config.api_url}/security-dashboard`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setData(response?.data.data);
          localStorage.setItem("user_id", response?.data.data?.userInfo?.id);
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
          });
        });
    }
  };
  React.useEffect(() => {
    getDashboardDetails();
    // eslint-disable-next-line
  }, []);

  const TopCarddata = [
    {
      id: 1,
      name: t("Visitors"),
      count: data?.visitors,
      image: "/images/visitorIMG.svg",
      color: "#46B9DA",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "visitors",
      borderColor: "#FFD869",
    },
    {
      id: 2,
      name: t("Workers"),
      count: data?.workers,
      image: "/images/worketIMG.svg",
      color: "#E29336",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "workers",
      borderColor: "#EE6B01",
    },
    {
      id: 3,
      name: t("Parking"),
      count: data?.parking,
      image: "/images/parkingIMG.svg",
      color: "#6248E2",
      link: "/gate_pass",
      redirect: 2,
      redirectLink: "parking",
      borderColor: "#50E6FF",
    },

    {
      id: 4,
      name: t("Vendor"),
      count: data?.vendors,
      image: "/images/vendorIMG.svg",
      color: "#F34276",
      link: "/vendor",

      redirectLink: "vendors",
      borderColor: "#199BE2",
    },
    {
      id: 5,
      name: t("Domestic_Helper"),
      count: data?.domesticHelpers,
      image: "/images/domesticIMG.svg",
      color: "#F34276",
      link: "/gate_pass",
      redirect: 0,
      redirectLink: "domesticHelpers",
      borderColor: "#707B85",
    },
    {
      id: 6,
      name: t("Service_Provider"),
      count: data?.serviceProviders,
      image: "/images/serviceProvider.svg",
      color: "#F34276",
      link: "/swiftinout",
      redirectLink: "serviceProviders",
      borderColor: "#FFD869",
    },
  ];
  const QuickCarddata = [
    {
      id: 2,
      name: t("Gate_Pass"),
      image: "/images/quickaccess2.svg",
      color: "#2B478B",
      onClick: () => {
        history.push("/getpassverification");
      },

      arrow: "/images/quickaccess2arrow.svg",
      bg: "#E9F1FA",
    },
    {
      id: 1,
      name: t("Swift_in"),
      image: "/images/quickaccess1.svg",
      color: "#CF6C20",

      onClick: () => setDrawer(true),
      arrow: "/images/quickaccess1arrow.svg",
      bg: "#FFF8EF",
    },
  ];
  const ActiveEntriesData = [
    {
      id: 1,
      name: t("Visitors"),
      count: data?.overStayVisitors,
      image: "/images/visitorIMG.svg",
      color: "#46B9DA",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "overStayVisitors",
      borderColor: "#FFD869",
    },
    {
      id: 2,
      name: t("Workers"),
      count: data?.overStayWorkers,
      image: "/images/worketIMG.svg",
      color: "#E29336",
      link: "/gate_pass",
      redirect: 1,
      redirectLink: "overStayWorkers",
      borderColor: "#EE6B01",
    },
    {
      id: 3,
      name: t("Parking"),

      count: data?.overStayParking,
      image: "/images/parkingIMG.svg",
      color: "#6248E2",
      link: "/gate_pass",
      redirect: 2,
      redirectLink: "overStayParking",
      borderColor: "#50E6FF",
    },

    {
      id: 4,

      name: t("Vendor"),
      count: data?.overStayVendors,
      image: "/images/vendorIMG.svg",
      color: "#F34276",

      link: "/vendor",
      redirectLink: "overStayVendors",
      borderColor: "#199BE2",
    },
    {
      id: 5,
      name: t("Delivery"),
      name1: t("Collections"),
      count: data?.deliveryRequest,
      image: "/images/domesticIMG.svg",
      color: "#F34276",
      link: "/gate_pass",
      redirect: 3,
      redirectLink: "deliveryRequest",
      borderColor: "#707B85",
    },
    {
      id: 6,
      name: t("Service"),
      name1: t("Provider"),
      count: data?.overStayServiceProviders,
      image: "/images/serviceProvider.svg",
      color: "#F34276",

      link: "/swiftinout",
      redirectLink: "overStayServiceProviders",
      borderColor: "#FFD869",
    },
  ];

  const renderForm = () => {
    return (
      <>
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
          <Box display="flex" alignItems="center" className={classes.titleroot}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                className={classes.bottomBarTitle}
              >
                {t("Swift_in")}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setDrawer(false)}>
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() =>
                  history.push(
                    `${Routes.getPassVerification}${"?title="}${"Domestic"}`
                  )
                }
              >
                <img src="/images/vendor.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Domestic_Help_Pass")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.vendor)}
              >
                <img src="/images/domestic.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Vendor_Entry")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.swiftInOut)}
              >
                <img src="/images/service.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  {t("Service_Provider")}
                </Typography>
              </Box>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };

  return (
    <>
      {load ? (
        <DashboardSimmer />
      ) : (
        <div className={classes.root}>
          <TopNavBars
            note
            handleClicknote={() => history.push(Routes.notification)}
          />
          <Box
            p={2}
            height={size?.height - 130}
            marginRight="-6px"
            overflow="auto"
          >
            {/* Profile*/}
            <Box display="flex" alignItems="center" marginTop="-8px">
              <Box flexGrow={1}>
                <Typography className={classes.username}>
                  {t("Hi") +
                    "," +
                    (decoded && decoded?.user_name ? decoded.user_name : "")}
                </Typography>
                <Typography className={classes.msg}>
                  {t("Good") +
                    (hour < 12
                      ? t("Morning")
                      : hour >= 12 && hour < 18
                        ? t("Afternoon")
                        : t("Evening")) +
                    "," +
                    t("Welcome_Back")}
                </Typography>
              </Box>
              <Box
                className={classes.relative}
                style={{ cursor: "pointer" }}
                onClick={() => history.push(Routes.profileView)}
              >
                <img
                  src={data?.userInfo?.user_image}
                  className={classes.profileImg}
                  alt=""
                />
                <Typography className={classes.absolute}>
                  &nbsp;&nbsp;{t("Security")}&nbsp;&nbsp;
                </Typography>
              </Box>
            </Box>

            {/* Dashboard Card */}
            <Typography className={classes.title}>
              {" "}
              {t("Today_Entries")}
            </Typography>
            <Box height="12px" />
            <Grid container spacing={1.3}>
              {TopCarddata?.map((item) => {
                return (
                  <Grid item xs={4}>
                    <HomepageCard data={item} />
                  </Grid>
                );
              })}
            </Grid>
            <Box height="12px" />
            <div className={classes.invoice}>
              <Typography className={classes.title}>
                {t("Quick_Access")}
              </Typography>
              <Box height="12px" />
              <Grid container spacing={1.3}>
                {QuickCarddata?.map((item) => {
                  return (
                    <Grid item xs={6}>
                      <QuickCard data={item} />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
            <Box height="12px" />
            {/* Active Entries Card */}
            <Typography className={classes.title}>
              {t("Overstay_Entries")}
            </Typography>
            <Box height="12px" />
            <Grid container spacing={1.3}>
              {ActiveEntriesData?.map((item) => {
                return (
                  <Grid item xs={4}>
                    <HomepageCard data={item} entries={true} />
                  </Grid>
                );
              })}
            </Grid>
            <Box height="12px" />

          </Box>
          {/* <WelcomeBanner name={decoded.username} open={open} closeDrawer={closeDrawer} /> */}
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={drawer}
              onClose={() => setDrawer(false)}
            >
              {renderForm()}
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={drawer}
              onClose={() => setDrawer(false)}
            >
              {renderForm()}
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default withNamespaces("dashboard")(Dashboard);
