import { Stack, Typography } from "@mui/material"
import { useStyles } from "./style"

export const CommonParking = (props) => {

    const {
        data = {}
    } = props
    const classes = useStyles(props)

    return <div className={classes.parking_units_div}>
        {data?.length > 0 ?
            <Stack direction={"column"} spacing={"16px"}
                divider={<div className={classes.horizontal_divider} />}>
                {data?.map((i) =>
                    <Stack direction={"row"} spacing={"10px"}
                        className={classes.common_parking_card}>
                        <div className={classes.common_parking_avatar}>
                            <img src="/images/carParking.svg" alt="" />
                        </div>
                        <Stack direction={"column"} spacing={"6px"}>
                            <Typography className={classes.common_parking_name}>
                                {i?.name}
                            </Typography>
                            <Typography className={classes.common_parking_type}>
                                {i?.type}
                            </Typography>
                        </Stack>
                    </Stack>)}
            </Stack> :
            <div className={classes.no_data_found_div}>
                <Typography className={classes.no_data_found_text}>No Common Parking Found</Typography>
            </div>}
    </div>
}