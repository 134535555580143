import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {},
  tabroot: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.secondary,
    padding: "8px 12px",
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "0px",
    minHeight: "auto",
    borderRadius: "20px",
    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "20px",
    },
    minWidth: "auto",

    marginRight: "8px",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "20px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },
  title: {
    padding: "8px 14px",
    borderRadius: "20px",
    // background: "#5078E1",
    color: "#071741",
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "20px",
    backgroundColor: " #071741",
    color: "white",
  },
}));
export const TabsComponent = ({
  selectedTab = 0,
  tab1Label = "Home",
  tab2Label = "Invoice",
  tab3Label = "Payment History",
  onTab1Clicked = () => false,
  onTab2Clicked = () => false,
  onTab3Clicked = () => false,
  onTab4Clicked = () => false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Tab Section */}
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            indicatorColor="none"
            textColor="secondary"
            className={classes.tabroot}
            variant="scrollable"
            visibleScrollbar={false}
            scrollButtons={false}
          >
            <Tab
              className={
                selectedTab === 0 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 0 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 0 && (
                    <img
                      src="/images/icons8-female-user-update.svg"
                      alt="img3"
                    />
                  )}
                  &nbsp;&nbsp;Swift In & Out
                </span>
              }
              onClick={onTab1Clicked}
            />
            <Tab
              className={
                selectedTab === 1 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 1 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 1 && (
                    <img src="/images/ticket.svg" alt="img1" />
                  )}
                  &nbsp;&nbsp;Visitors
                </span>
              }
              onClick={onTab2Clicked}
            />
            <Tab
              className={
                selectedTab === 2 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 2 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 2 && (
                    <img
                      src="/images/icons8-delivered-box (1).svg"
                      alt="img2"
                    />
                  )}
                  &nbsp;&nbsp;Parkings
                </span>
              }
              onClick={onTab3Clicked}
            />
            <Tab
              className={
                selectedTab === 3 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 3 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 3 && (
                    <img
                      src="/images/icons8-delivered-box (1).svg"
                      alt="img3"
                    />
                  )}
                  &nbsp;&nbsp;Delivery Collection
                </span>
              }
              onClick={onTab4Clicked}
            />
          </Tabs>
        </Grid>
      </Grid>
    </div>
  );
};
