import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Backdrop,
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
      paddingTop: "4px",
    },
    height: "64px",
    boxShadow: "0px -1px 6px #00000021",
  },
  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.palette.primary.main,
  },
  unselectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.typography.color.Tertiary,
  },
  menuText: {
    fontSize: "14px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  menuItem: {
    position: "absolute",
    padding: 0,
    background: theme.palette.common.white,
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(9),
    [theme.breakpoints.down("sm")]: {
      right: "5%",
    },
    [theme.breakpoints.up("sm")]: {
      left: "58%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
  box: {
    border: "1px solid #E4E8EE",
    padding: "8px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px 12px 8px 12px",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  text: {
    fontSize: "11px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.palette.secondary.main,
  },
}));
export const BottomNavbar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const [drawer, setDrawer] = React.useState(false);

  const renderForm = () => {
    return (
      <>
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
          <Box display="flex" alignItems="center" className={classes.titleroot}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                className={classes.bottomBarTitle}
              >
                Swift In & Out
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setDrawer(false)}>
                <CloseIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
          <Grid container spacing={2} p={2}>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(`${Routes.getPassVerification}${"?title="}${"Domestic"}`)}
              >
                <img src="/images/vendor.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  Domestic Help Pass
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.vendor)}
              >
                <img src="/images/domestic.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>Vendor Entry</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                className={classes.box}
                onClick={() => history.push(Routes.swiftInOut)}
              >
                <img src="/images/service.svg" alt="vendorimg" />
                &nbsp;
                <Typography className={classes.text}>
                  Service Provider
                </Typography>
              </Box>
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };
  return (
    <>
      <Grid
        container
        justifyContent={"space-around"}
        alignItems={"center"}
        className={classes.containers}
      >
        <Grid
          item
          xs={2}
          className={classes.tabStyle}
          onClick={props.onTab1Clicked}
        >
          <center>
            <img
              src={
                window.location.pathname === "/"
                  ? "/images/dashboardselected.svg"
                  : "/images/dashboard_unselected.svg"
              }
              alt="dashboard"
            />
            <Typography
              className={
                window.location.pathname === "/"
                  ? classes.selectedLabelTextStyle
                  : classes.unselectedLabelTextStyle
              }
            >
              {"Dashboard"}
            </Typography>
          </center>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.tabStyle}
          onClick={props.onTab2Clicked}
        >
          <center>
            <img
              src={
                window.location.pathname === "/gate_pass"
                  ? "/images/entiresselect.svg"
                  : "/images/Group 7042.svg"
              }
              alt="gate_pass"
            />
            <Typography
              className={
                window.location.pathname === "/gate_pass"
                  ? classes.selectedLabelTextStyle
                  : classes.unselectedLabelTextStyle
              }
            >
              {"Entries"}
            </Typography>
          </center>
        </Grid>

        {/* <Grid
          item
          xs={2}
          className={classes.tabStyle}
          onClick={props.onTab4Clicked}
        >
          <center>
            <img
              // src={
              //   window.location.pathname === "/gate_pass"
              //     ? "/images/lead_selected.svg"
              //     : "/images/lead_unselected.svg"
              // }
              src="/images/lead_unselected.svg"
              alt="gate_pass"
            />
            <Typography
              className={
                window.location.pathname === "/requestscreen"
                  ? classes.selectedLabelTextStyle
                  : classes.unselectedLabelTextStyle
              }
            >
              {"Info"}
            </Typography>
          </center>
        </Grid> */}
        <Grid
          item
          xs={2}
          className={classes.tabStyle}
          onClick={props.onTab3Clicked}
        >
          <center>
            <img
              src={
                window.location.pathname === "/info"
                  ? "/images/info_selected.svg"
                  : "/images/info.svg"
              }
              alt="request"
            />
            <Typography
              className={
                window.location.pathname === "/info"
                  ? classes.selectedLabelTextStyle
                  : classes.unselectedLabelTextStyle
              }
            >
              {"Info"}
            </Typography>
          </center>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.tabStyle}
          onClick={props.onTab4Clicked}
        >
          <center>
            <Avatar
              variant="circular"
              style={{
                height: "46px",
                width: "46px",
                border: "2px solid #CCD3E4",
                backgroundColor: "#5078E1",
              }}
              onClick={handleToggle}
            >
              <img
                src={open ? "/images/search_selected.svg" : "/images/qr.svg"}
                alt="search"
              />
            </Avatar>
            <Backdrop
              className={classes.backdrop}
              open={open}
              onClick={handleClose}
            >
              <div className={classes.menuItem}>
                <MenuItem
                  onClick={() => history.push(Routes.getPassVerification)}
                >
                  <Typography className={classes.menuText}>
                    &nbsp; Gate Pass Verification
                  </Typography>
                </MenuItem>
                <MenuItem onClick={() => setDrawer(true)}>
                  <Typography className={classes.menuText}>
                    &nbsp; Swift In & Out
                  </Typography>
                </MenuItem>
              </div>
            </Backdrop>
          </center>
        </Grid>
        {/* <Grid
          item
          xs={2}
          className={classes.tabStyle}
          onClick={props.onTab5Clicked}
        >
          <center>
            <img
              src={
                window.location.pathname === "/profileView"
                  ? "/images/profile_selected.svg"
                  : "/images/profile_unselected.svg"
              }
              alt="profile"
            />
            <Typography
              className={
                window.location.pathname === "/profileView"
                  ? classes.selectedLabelTextStyle
                  : classes.unselectedLabelTextStyle
              }
            >
              {"Profile"}
            </Typography>
          </center>
        </Grid> */}
      </Grid>
      {/* hidden */}
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </>
  );
};
