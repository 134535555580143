import { Avatar, Box, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  card: {
    // cursor: "pointer",
    // padding: "0px 12px",
    // backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    overflow: "hidden",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  Cardcontent: {
    // [theme.breakpoints.up("sm")]: {
    //   padding: "8px 12px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px 0px",
    // },
    // borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  received: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#FF9340",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  receivedDetail: {
    fontSize: "12px",
    color: "#FF9340",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#FFF4EB",
    padding: "1px 8px",
    borderRadius: "4px",
    marginTop: "6px",
    display: "inline",
  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  collected: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  box: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "120px",
    width: "110px",
    objectFit: "contain"
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
}));

export const DeliveryCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  return (
    <>
      <div
        className={classes.card}
        onClick={() =>
          history.push(Routes.deliveryDetails + "?passId=" + props?.data?.id)
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex" position={"relative"}>
            {props?.data?.visitor_image ?
              <Avatar src={props?.data?.visitor_image} alt={props?.data?.visitor_image} variant="rounded" className={classes.avatar} /> :
              <Avatar className={classes.avatar} variant="rounded" />
            }
            <div className={classes.bottomText}>
              <Typography fontSize={"10px"}>
                {props?.data?.request_no}
              </Typography>
            </div>
            <Box flexGrow={1} marginLeft="10px">
              <Box display="flex" alignItems="center" justifyContent={"space-between"}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    {/* <Hidden smUp>
                      {props?.data?.title?.length > 15 ? (
                        <>{props?.data?.title.slice(0, 15)}...</>
                      ) : (
                        props?.data?.title
                      )}
                    </Hidden>
                    <Hidden smDown>
                      {props?.data?.title?.length > 40 ? (
                        <>{props?.data?.title.slice(0, 40)}...</>
                      ) : (
                        props?.data?.title
                      )}
                    </Hidden> */}
                    <Hidden smUp>Collection Request</Hidden>
                    <Hidden smDown>Collection Request</Hidden>
                  </Typography>
                </Box>

                <Box marginRight="10px">
                  {props?.data?.delivery_status === "Yet To Receive" && (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      className={classes.progress}
                    >
                      Yet To Receive
                    </Typography>
                  )}

                  {props?.data?.delivery_status === "Collected" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.collected}
                      style={{ backgroundColor: "#5AC782" }}
                    >
                      Collected
                    </Typography>
                  )}
                  {props?.data?.delivery_status === "Received At Gate" && (
                    <>
                      <Typography
                        variant="subtitle2"
                        noWrap
                        className={classes.received}
                      >
                        Received
                      </Typography>
                    </>
                  )}
                  {props?.data?.delivery_status === "Cancelled" && (
                    <Typography variant="subtitle2" className={classes.cancel}>
                      Cancelled
                    </Typography>
                  )}
                </Box>
              </Box>


              <Box className={classes.box} alignItems="center" display="flex" marginTop="4px">

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.property_name}
                    </Typography>
                  </Box>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box className={classes.dot} />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.unit?.[0]?.unit_no}
                    </Typography>
                  </Box>
                </div>








              </Box>
              <Box className={classes.box} alignItems="center" display="flex" marginTop="4px">

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.description ? props?.data?.description : ""}
                    </Typography>
                  </Box>
                </div>


              </Box>
              <Box className={classes.box} alignItems="center" display="flex" marginTop="4px">

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.updated_at ? "Last updated at : " + moment(props?.data?.updated_at)
                        .tz(moment.tz.guess())
                        .format("DD MMM YY hh:mm") : ""}
                    </Typography>
                  </Box>
                </div>


              </Box>
            </Box>

          </Box>
        </div>
      </div>
    </>
  );
};
