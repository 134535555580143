import React from "react";
import { SwiftInOutDetails } from "./swiftInOutDetails";

class SwiftInOutDetailsParent extends React.Component {
  render() {
    return <SwiftInOutDetails />;
  }
}

export default SwiftInOutDetailsParent;
