import { Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { withNamespaces } from "react-i18next";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,

    backgroundImage: `url(/images/welcome.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "65% 65%",
    backgroundPosition: "center"


  },
  welcomeText: {
    fontSize: "26px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: "white",
  },
  btn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.tertiary1,
    padding: "12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "opx 6px 10px #00000014",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.tertiary1,
    },
  },
  img: {
    width: "auto",
    height: "36px",
  },
  btnRoot: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    right: 0,
    left: 0,
    [theme.breakpoints.up("sm")]: {
      padding: "0px 45px",
    },
    // padding: " 0px 16px"
  },
  poweredby: {
    display: "flex",
    alignItems: "center",
    fontSize: "10px",
    color: "white",
  },
  pa: {
    color: "white",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
  },
}));

const WelcomePage = (props) => {
  const { t } = props;

  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);

  const size = useWindowDimensions();
  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get("token");
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);

  const getStarted = () => {
    const payload = {
      userId: decoded.user_id,
    };
    NetworkCall(
      `${config.auth_api_url}/auth/verifyUser/?token=${welcomePageAuthToken}`,
      NetWorkCallMethods.post,
      payload,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.is_allowed) {
            history.push(
              Routes.setupAccount + "?welcomeAuthToken=" + welcomePageAuthToken
            );
          } else {
            history.push(Routes.login);
          }
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };
  return (
    <Container maxWidth="sm" sx={{ padding: 0 }}>
      <div className={classes.root} style={{ height: size?.height }}>
        <Container maxWidth="sm">
          <Grid container>
            <Grid container paddingTop={2}>
              {/* <Grid item xs={12}>
                <img
                  src="/images/casagrandLogo.png"
                  alt="casagrandLogo"
                  className={classes.img}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Typography className={classes.welcomeText}> {t("Welcome")}
                </Typography>
                <Typography className={classes.welcomeText}>
                  {decoded && decoded.username ? decoded.username : ""},
                </Typography>

              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="sm" className={classes.btnRoot}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                className={classes.btn}
                onClick={getStarted}
              >
                {t("Get_Started")}

              </Button>
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Container>
  );
};
export default withNamespaces("welcomePage")(WelcomePage);