import { Avatar, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    overflow: "hidden",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  Cardcontent: {
    // [theme.breakpoints.up("sm")]: {
    //   padding: "8px 12px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px 4px",
    // },
    // borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  checked: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5078E11E",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "120px",
    width: "110px",
    objectFit: "contain"
  },
  bold: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.secondary,

  },
  semiBold: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,

  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
}));

export const PassList = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  return (
    <>
      <div
        className={classes.card}
        onClick={() =>
          history.push(Routes.passDetails + "?passId=" + props?.data?.id)
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex" position={"relative"}>
            {props?.data?.visitors?.[0]?.visitor_image ?
              <Avatar src={props?.data?.visitors?.[0]?.visitor_image} alt={props?.data?.visitors?.[0]?.visitor_image} variant="rounded" className={classes.avatar} /> :
              <Avatar className={classes.avatar} variant="rounded" />
            }
            <div className={classes.bottomText}>
              <Typography fontSize={"10px"}>
                {props?.data?.request_no}
              </Typography>
            </div>
            <Box flexGrow={1} marginLeft="10px">
              <Box alignItems="center" display="flex" justifyContent={"space-between"}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    {props?.data?.request_type === "VGR" && "Visitor"}
                    {props?.data?.request_type === "WGR" && "Worker"}
                  </Typography>
                </Box>
                <Box marginRight="10px">
                  {props?.data?.status === "Pending" && (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      className={classes.progress}
                    >
                      In Progress
                    </Typography>
                  )}
                  {props?.data?.status === "Approved" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.approved}
                    >
                      Approved
                    </Typography>
                  )}
                  {props?.data?.status === "Rejected" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.rejected}
                    >
                      Rejected
                    </Typography>
                  )}
                  {props?.data?.status === "Cancelled" && (
                    <Typography variant="subtitle2" className={classes.delete}>
                      Cancelled
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                marginTop="4px"
                style={{ flexFlow: "wrap !important" }}
                className={classes.boxes}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.property_name}
                    </Typography>
                  </Box>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {props?.data?.unit?.[0]?.unit_no && <Box className={classes.dot} />}
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.unit?.[0]?.unit_no}
                    </Typography>
                  </Box>
                </div>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                marginTop="4px"
                style={{ flexFlow: "wrap !important" }}
                className={classes.boxes}
              >

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.semiBold}
                      noWrap
                    >
                      {props?.data?.visitors?.[0]?.name}
                    </Typography>
                  </Box>
                </div>
              </Box>
              <Box
                alignItems="center"
                display="flex"
                marginTop="4px"
                style={{ flexFlow: "wrap !important" }}
                className={classes.boxes}
              >

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <DateIcon /> &nbsp;
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.bold}
                      noWrap
                    >
                      {moment(props?.data?.request_from)
                        .format("DD MMM YY")}
                      &nbsp;-&nbsp;
                      {moment(props?.data?.request_to)
                        .format("DD MMM YY ")}
                    </Typography>
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
