import React from "react";
import withBottombar from "../../HOCs/withbottombar";
import { SwiftInOutList } from "./list";
class SwiftInOutParent extends React.Component {
  render() {
    return <SwiftInOutList />;
  }
}

export default withBottombar(SwiftInOutParent);
