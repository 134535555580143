import { NetWorkCallMethods } from "../utils";
import { NetworkCall } from "../networkcall";
import { config } from "../config";
import { LocalStorageKeys } from "./constants";
import { BiCycle, Car, TwoWheeler } from "../assets";

const companyID = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))?.[0]?.company_id


export const mapResult = (map) => {
      let updateData = {
            latitude: map?.lat,
            longitude: map?.lng
      };
      if (map) {
            let keys = ['doorNo', 'addressLineOne', 'addressLineTwo', 'landmark', 'area', 'district', 'city', 'state', 'country', 'pincode', 'zipcode'];
            let findAddress = map?.result?.address_components;
            keys?.map(_ => {
                  switch (_) {
                        case 'doorNo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["premise"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineOne':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["establishment", "street_number"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineTwo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["neighborhood", "route",]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'landmark':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('landmark'))?.long_name ?? ''
                              break;
                        case 'area':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes("political", "locality"))?.long_name ?? ''
                              break;
                        case 'district':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'city':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'state':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_1'))?.long_name ?? ''
                              break;
                        case 'country':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('country'))?.long_name ?? ''
                              break;
                        case 'pincode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        case 'zipcode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        default:
                              return null
                  }
                  return _
            })
      }

      return updateData
}

// sigle images
export const singleImageUpload = async (data, type) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("tenantId", `${config.tenantid}`);
      if (companyID) {
            formData.append("companyId", `${companyID}`);

      }
      formData.append("type", `${type}`);
      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )

      return image.data.fileUrls[0].url

}

// multiple images
export const multiImageUpload = async (data, { companyId }) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("tenantId", `${config.tenantid}`);
      formData.append("companyId", `${companyId}`);

      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )
      return image.data.fileUrls[0].url

};


// const images type
export const assestType = {
      Facility_Images: 1,
      General_Images: 2,
      Videos: 3,
      Documents: 4,
      Three_sixty_Images: 5,
      Thumbnails: 6,
      Floor_Plan: 7

}

/**
 * Function to get ENUM dynamically
 * @param enumTypes `[String]`
 * @returns `Object`
 */
export const enumSelect = async (enumTypes) => {
      let result;

      const payload = { enumName: enumTypes, };

      await NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload, null, true, false
      ).then((res) => {
            result = res?.data?.data;
      }).catch((err) => {
            console.log(err)
      })

      return result;
};

//for key
export const keyMapSource = ({ enumType }) => {
      return enumType?.flatMap((item, index) => {
            return {
                  [`type${String.fromCharCode(64 + (index + 1))}`]: item?.value
            }
      }).reduce((old, item) => (
            { ...old, ...item }
      ), {})

}
//enum modifier
export const customEnumModifier = ({ filterKey, replaceKey, actualKey, data }) => {


      return data?.filter(i => i.value !== filterKey)?.map(i => {
            return {
                  label: i.label === actualKey ? replaceKey : i.label,
                  value: i.value
            }
      })



}

export const enumCatagory = {
      notifications_content_type: 'notifications_content_type',
      security_status_enum_type: 'security_status_enum_type',
      security_request_type: 'security_request_type',
      delivery_status_type: 'delivery_status_type',
      general_maintenance_type: 'general_maintenance_type',
      swift_type: 'swift_type',
      payment_mode: 'payment_mode'
}

export const vehicleComponent = {
      "FOUR-WHEELER": (<Car />),
      "TWO-WHEELER": (<TwoWheeler />),
      "BI-CYCLE": (<BiCycle />)
}

export const vehicleTypeSVG = {
      "FOUR-WHEELER": "/images/carSVG.svg",
      "TWO-WHEELER": "/images/bikeSVG.svg",
      "BI-CYCLE": "/images/bicycleSVG.svg"
}

export const vehicleTypeBGSVG = {
      "FOUR-WHEELER": "/images/parkingSlotCarBGSVG.svg",
      "TWO-WHEELER": "/images/parkingSlotBikeBGSVG.svg",
      "BI-CYCLE": "/images/parkingSlotBicycleBGSVG.svg"
}