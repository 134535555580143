import {
      Box,
      Button,
      Container,
      Grid,
      Typography
} from "@mui/material";
import axios from "axios";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, roles, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { CompanyList } from './component/companyselect';
import { useStyles } from "./style";
import { updateDeviceToken } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";


const CompanySelect = (props) => {
      const { t } = props;

      const { state } = useLocation();
      // history
      const history = useHistory()
      const alert = React.useContext(AlertContext);
      const backdrop = React.useContext(BackdropContext);
      // state
      const [client, setClient] = React.useState(state?.[0] ?? "")
      const [disable, setDisable] = React.useState(false)
      // classes
      const classes = useStyles();
      // useDimension
      const size = useWindowDimensions();

      React.useEffect(() => {
            backdrop.setBackDrop({ ...backdrop, open: true, message: "", });
            if (client?.id) {
                  if (state?.length === 1) {
                        submit()
                  } else {
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  }
            } else {
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
            }
            // eslint-disable-next-line
      }, [client?.id]);
      // add client
      const companySelect = (client) => {
            setClient(client)
      }

      //get client
      const getClientId = (roleID) => {
            axios.get(`${config.auth_api_url}/auth/select_role?role_id=${roleID}`,
                  { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}` } }
            ).then((res) => {

                  localStorage.setItem(LocalStorageKeys.authToken, res.data.token)
                  localStorage.setItem(LocalStorageKeys.client, JSON.stringify(res?.data?.selected_client))
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  setDeviceToken(res?.data?.userProfile?.[0]?.id, res?.data?.selected_client?.id)
                  setDisable(false)
                  history.push({
                        pathname: Routes.dashboard,
                        state: true
                  })

            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some thing went wrong",
                  });
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                  setDisable(false)

            });
      }
      //submit
      const submit = () => {
            setDisable(true)
            axios.get(`${config.auth_api_url}/auth/select_client?client_id=${client?.id}`,
                  { headers: { Authorization: `Bearer ${localStorage.getItem(LocalStorageKeys.authToken)}` } }
            ).then((res) => {

                  const rolesName = res?.data?.userProfile?.[0]?.roles.map((val) => { return val.name });
                  const isSecurityGuard = (name) => name === roles.securityGuard;
                  if (rolesName.some(isSecurityGuard)) {
                        //set datas in local
                        let role = res?.data?.userProfile?.[0]?.roles?.filter((val) => val.name === roles.securityGuard);
                        localStorage.setItem(LocalStorageKeys.authToken, res.data.token)
                        localStorage.setItem(LocalStorageKeys.accessType, JSON.stringify(role))
                        //localStorage.setItem(LocalStorageKeys.userProfileId, res?.data?.userProfile?.[0]?.id)
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                        //get client
                        getClientId(role?.[0]?.role_id)

                  } else {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Sorry You Don't Have Access",
                        });
                        localStorage.clear();
                        backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                        history.push(Routes.login)
                  }


            }).catch((err) => {
                  alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some thing went wrong",
                  });
                  backdrop.setBackDrop({ ...backdrop, open: false, message: "", });

                  setDisable(false)
            });
      }
      const setDeviceToken = (val, client) => {
            if (localStorage.getItem(LocalStorageKeys.deviceToken)) {
                  const variables = {
                        query: updateDeviceToken,
                        variables: {
                              insert: [
                                    {
                                          user_profile_id: val,
                                          token: localStorage.getItem(LocalStorageKeys.deviceToken),
                                          is_active: true,
                                          client: client,
                                          app_build: config.app_x_build
                                    },
                              ],
                        },
                  };

                  NetworkCall(
                        `${config.graphql_url}`,
                        NetWorkCallMethods.post,
                        variables,
                        null,
                        true,
                        false
                  )
                        .then((response) => {
                              backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                        })
                        .catch((error) => {
                              backdrop.setBackDrop({ ...backdrop, open: false, message: "", });
                              console.log(error)
                        });
            }
      };
      return (<div>
            <Container maxWidth="sm" style={{ padding: 0 }}>
                  <Grid container>
                        <Grid item className={classes.content}
                              style={{ height: size.height > 620 ? size.height - 132.5 : 488.5 }}>
                              <Grid item xs={12} className={classes.backgroundImage} />
                              <Grid style={{ padding: "0px 16px 0px 16px" }}>
                                    <Box height={"24px"} />
                                    <Grid item xs={12}>
                                          <Typography className={classes.text}>
                                                Select Client
                                          </Typography>
                                    </Grid>
                                    <Box height={"16px"} />
                                    <Grid item xs={12}>
                                          <div className={classes.list}
                                                style={{
                                                      height: size.height > 620 ? size?.height - 421 : 200,
                                                      overflow: "auto", padding: "16px"
                                                }}>
                                                {state?.map((value) => {
                                                      return (<CompanyList
                                                            data={value}
                                                            companySelect={companySelect}
                                                            seleted={client?.id} />)
                                                })}
                                          </div>
                                    </Grid>
                              </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
                              <Button fullWidth variant="contained" disabled={disable}
                                    className={classes.btn} onClick={submit}>
                                    {t("Continue")}
                              </Button>
                              <Typography className={classes.goBackStyle}
                                    onClick={() => history.push(Routes.login)}>
                                    {t("Go_Back")}
                              </Typography>
                        </Grid>
                  </Grid>
            </Container>
      </div>);
};
export default withNamespaces("companySelectPage")(CompanySelect);