import React from "react";
import { DeliveryDetails } from "./passDetails";

class GatePassDetailsParent extends React.Component {
  render() {
    return <DeliveryDetails />;
  }
}

export default GatePassDetailsParent;
