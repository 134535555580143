import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { LocalStorageKeys } from "../../utils";
const useStyles = makeStyles((theme) => ({
  grow: {},
  appBar: {
    height: "64px",
    backgroundColor: theme.palette.background.tertiary1,
    boxShadow: "0px 2px 6px #00000014"
  },
  navbarimg: {
    height: "30px",
  },
  id: {
    color: "white",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    fontSize: "14px",
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
}));

export const TopNavBars = (props) => {
  const classes = useStyles(props);
  const logo = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType)) ? JSON.parse(localStorage.getItem(LocalStorageKeys.accessType)).filter((val) => val.name === "Security Guard").find((item) => item?.company_logo !== null) : null;
  const clientLogo = localStorage.getItem(LocalStorageKeys.clientLogo)
  return (
    <Grid
      container
      className={classes.appBar}
      justifyContent={"space-between"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Grid
        container
        xs={6}
        alignContent={"center"}
        alignItems={"center"}
        style={{ padding: "0px 16px" }}
      >
        <img
          src={logo?.company_logo ?? clientLogo}
          alt="logo"
          className={classes.navbarimg}
        />
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
        xs={6}
        style={{ padding: "0px 16px" }}
      >
        <Box />
        {props?.note && (
          <IconButton
            size="small"
            color="secondary"
            onClick={props.handleClicknote}
          >
            <img src="/images/notificationICON.svg" alt="note" />
          </IconButton>
        )}
        {props?.home && (
          <Typography className={classes.id}>
            {props?.agreementNo?.map((val) => {
              return (
                <>
                  {val?.agreement_no === props?.checkno
                    ? val?.agreement_no
                    : ""}
                </>
              );
            })}
            <IconButton
              size="small"
              onClick={props.handleClick}
              style={{
                color: "white",
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
