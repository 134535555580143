export const Routes = {
  // home: "/",
  login: "/login",
  welcomePage: "/welcome",
  setupAccount: "/setupaccount",
  otpPage: "/otppage",
  setpassword: "/setpassword",
  dashboard: "/",
  gateEntry: "/gateentry",
  getPassVerification: "/getpassverification",
  gokul: "/gokul",
  gatePass: "/gate_pass",
  passDetails: "/gate_pass_details",
  deliveryDetails: "/delivery_details",
  parkingSlotDetails: "/parking_slot_details",
  swiftInOutDetails: "/swiftinout_details",
  swiftInOut: "/swiftinout",
  create: "/create",
  details: "/details",
  vendor: "/vendor",
  profileView: "/profileView",
  profileEdit: "/profileedit",
  addAddress: "/addaddress",
  companyselect: '/companyselect',
  info: "/info",
  notification: "/notification",
  announcement_details:"/announcement_details"
};
