import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
  },

  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  cardContent: {
    backgroundColor: "white",
    margin: "12px 10px 8px 14px",

    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },
  title: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#4E5A6B",
  },
  sub: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    color: "#4E5A6B",
  },
  heading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    marginTop: "10px",
  },
  detailsection: {
    borderBottom: "1px dashed #E4E8EE",
    padding: "12px 4px",
  },
  detailsection2: {
    borderTop: "1px dashed #E4E8EE",
  },
  alertsection: {
    backgroundColor: (props) =>
      props?.detail?.delivery_status === props?.statusKey?.type2
        ? "#78B1FE"
        : props?.detail?.delivery_status === props?.statusKey?.type1
          ? "#FF9340"
          : props?.detail?.delivery_status === props?.statusKey?.type4
            ? "#5AC782"
            : "#CED3DD",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
  },
  msg: {
    fontSize: "12px",
    color: "white",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    textAlign: "center",
  },
}));

export const DetailCard = (props) => {
  const classes = useStyles(props);
  return (
    <>
      <div className={classes.cardContent}>
        <Box
          display="flex"
          alignItems="center"
          className={classes.alertsection}
          justifyContent="center"
          p={1.8}
        >
          <Box>
            <center>
              {props?.detail?.delivery_status === "Received At Gate" ? (
                <CheckCircleIcon
                  style={{ color: "white", textAlign: "center" }}
                />
              ) : (
                <ErrorIcon style={{ color: "white", textAlign: "center" }} />
              )}

              <Typography className={classes.msg}>
                {props?.detail?.delivery_status}
                {/* {props?.detail?.delivery_status === props?.statusKey?.type2 &&
                  props?.statusKey?.type2}
                {props?.detail?.delivery_status === props?.statusKey?.type1 &&
                  props?.statusKey?.type1}
                {props?.detail?.delivery_status === props?.statusKey?.type4 && props?.statusKey?.type4} */}
              </Typography>
            </center>
          </Box>
        </Box>
        <Box p={1.6} marginTop="-10px">
          <Box
            display="flex"
            alignItems="center"
            className={classes.detailsection}
          >
            <Box className={classes.img}>
              <img
                src="/images/deliverycard.svg"
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginLeft="14px">
              <Typography className={classes.title}>
                {props?.detail?.name}
              </Typography>
              <Typography className={classes.sub}>
                Requested on 02 Dec
                {moment(props?.detail?.request_on)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}
              </Typography>
            </Box>
            <Box></Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                REFERENCE ID
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {props?.detail?.request_no}
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                GATE ENTRY
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {props?.detail?.gate}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.heading}>
                UNIT
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {props?.detail?.unit?.[0]?.name}
              </Typography>
              <Typography variant="subtitle2" className={classes.heading}>
                TENTATIVE DELIVERY
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                {moment(props?.detail?.request_from)
                  .tz(moment.tz.guess())
                  .format("DD MMM YY hh:mm:a")}
                <Box height="2px" />
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {props?.detail?.delivery_status === "Received At Gate" ||
          props?.detail?.delivery_status === "Collected" ? (
          <Box p={1.6} className={classes.detailsection2}>
            <Grid container sx={{ marginTop: "-10px" }}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  RECEIVED BY
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  {props?.detail?.delivery_collected_by}
                </Typography>
                <Typography variant="subtitle2" className={classes.heading}>
                  RECEIVED TIME
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  {moment(props?.detail?.received_date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY hh:mm:a")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  RECEIVED GATE
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  noWrap
                >
                  {props?.detail?.collected_access_gate}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )}
        {props?.detail?.delivery_status === "Collected" ? (
          <Box p={1.6} className={classes.detailsection2}>
            <Grid container sx={{ marginTop: "-10px" }}>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  COLLECTED GATE
                </Typography>
                <Typography variant="subtitle2" className={classes.title}>
                  {props?.detail?.collected_access_gate}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.heading}>
                  COLLECTED TIME
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  noWrap
                >
                  {moment(props?.detail?.delivery_collected_date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY hh:mm:a")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
