import gql from 'graphql-tag';
// export const yourQuery = gql ``;
export const SECURITY_MAIN = `
   query($id:String){
    user_profiles(where:  {
      user_id:{eq:$id}
      client:{eq:$clientId}
      is_active: { eq: true }
    }

    ){
      id
    }
  }`;

export const SECURITY_ACCESS = `
 query($user_profile_id: String, $role_id: String) {
  access_control(
    where: {
      user_profile_id: { eq: $user_profile_id }
      roles: { id: { eq: $role_id } }
      is_active: { eq: true }
            client:{eq:$clientId}

    }
  ) {
    user_profile_id
    user_profile {
      id
      first_name
    }
    property {
      label:id
      value:name
    }
    roles {
      id
      name
    }
  }
}
`;

export const GET_GATE_MASTER = `
 query($property_id: String) {
  access_gates_master(
    where: {
      property_id: { eq: $property_id }
      is_active: { eq: true }
            client:{eq:$clientId}

    }
  ) {
    id
    name
    user_profile_id
    user_profilesByID{
      id
      first_name
      mobile_no_country_code
      mobile_no
      image_url
    }
    description
    gate_no
    is_active
    property {
      id
      name
    }
  }
}
`;
export const GET_PROPERTY_BY_ROLE = `
query($user_profile_id: String, $role_id: String) {
  access_control(
    where: {
      user_profile_id: { eq: $user_profile_id }
      roles: { id: { eq: $role_id } }
      is_active: { eq: true }
            client:{eq: $clientId}

    }
  ) {
    user_profile_id
    user_profile {
      id
      first_name
    }
    property(where: { is_active: { eq: true }, client:{eq: $clientId}
 }) {
      value: id
      label: name
      access_gates_master(where: { is_active: { eq: true },  client:{eq: $clientId}
 }) {
        value: id
        label: name
      }
    }
    roles {
      id
      name
    }
  }
}

`;
export let GET_PROPERTY_BY_ROLES = (offset, limit, searchText, roleId, userId, clientId) => gql`
query {
 count: access_control(
   where: {
     user_profile_id: { eq: "${userId}" }
     roles: { id: { eq: "${roleId}" } }
     is_active: { eq: true }
           client:{eq:"${clientId}"}

   }
 ) {
   property(where: { is_active: { eq: true } },
               client:{eq:"${clientId}"}

    name: { iregex: "${searchText}" }) {
     count: count_id
   }
 }
 access_control(
   where: {
     user_profile_id: { eq: "${userId}" }
     roles: { id: { eq: "${roleId}" } }
     is_active: { eq: true }
                client:{eq:"${clientId}"}

   }
 ) {
   value: id
   property(
     where: { is_active: { eq: true } }
     name: { iregex: "${searchText}" }
                client:{eq:"${clientId}"}

     offset: ${offset}
     limit: ${limit}
   ) {
     value: id
     label: name
   }
 }
}

`;
export const GETCONTACT = `
query($id:String){
  user_profiles(where:{
    user_id:{eq:$id}
        client:{eq:$clientId}

  }){
    user_id
    contact_id
    id
  }
}`;
export const GET_GATE_PROPERTY = `
query($id:[String]){
  access_gates_master(where:{property_id:{in:$id},
      is_active: { eq: true }
            client:{eq:$clientId}

  }){
   value: id
    label:name
  }
}`;
export const DOMESTIC_SERVICE_VENDOR = `
query GET_ENTRIES_FOR_DOMESTICT_HELPER_VENDOR_SERVICE_PROVIDER_BY_VISITOR_TYPE(
  $visitorType: [String]
  $property_id: [String]
) {
  swift_in_out_log(
    where: {
      visitor_type: { in: $visitorType }
      property_id: { in: $property_id }
      is_active: { eq: true }
            client:{eq:$clientId}

    }
        order_by:{check_in_time:desc}

  ) {
    id
    visitor_type
    person_name
    reference_id
    unit:swift_in_out_log_units{
      id
      unit{
        id
        unit_no
        name
      }
    }
    access_gates_master{
      gate_no
      name
    }
    check_in_byByID(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      first_name
      last_name
    }
    check_in_time
    check_out_byByID(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      first_name
      last_name
    }
    check_out_time
    domestic_helper_request(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      helper(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        name
        profession_master{
          description
        }
        mobile_country_code
        mobile_no
        created_by(where: { is_active: { eq: true },client:{eq:$clientId} }) {
          id
          first_name
          last_name
        }
      }
      created_at
      request_status
      unit(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        unit_no
      }
      created_by(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        first_name
        last_name
      }
      profession(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        name
      }
      reference_id
      remarks
    }
    property(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      property_no
      name
    }
    vendor_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      image_url
    }
    service_providers_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      image_url
    }
    domestic_helper_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
    }
  }
}
`;

export const DOMESTIC_SERVICE_VENDOR_DETAILS = `
query GET_ENTRIES_FOR_DOMESTICT_HELPER_VENDOR_SERVICE_PROVIDER_BY_VISITOR_TYPE(
  $id: String
) {
  swift_in_out_log(where: { id: { eq: $id }, is_active: { eq: true }
        client:{eq:$clientId}

  }) {
    id
    visitor_type
    person_name
    vendor_image
    vehicle_image
    reference_id
    access_gates_master {
      gate_no
      name
    }
    check_in_byByID(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      first_name
      last_name
    }
    check_in_by
    check_in_time
    check_out_byByID(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      first_name
      last_name
    }
    check_out_time
    domestic_helper_request(where: { is_active: { eq: true }, client:{eq:$clientId} }) {
      id
      helper(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        name
        profession_master {
          description
        }
        mobile_country_code
        mobile_no
        created_by(where: { is_active: { eq: true },client:{eq:$clientId} }) {
          id
          first_name
          last_name
        }
      }
      created_at
      request_status
      unit(where: { is_active: { eq: true }, client:{eq:$clientId} }) {
        id
        unit_no
      }
      created_by(where: { is_active: { eq: true } , client:{eq:$clientId}}) {
        id
        first_name
        last_name
      }
      profession(where: { is_active: { eq: true }, client:{eq:$clientId} }) {
        id
        name
      }
      reference_id
      remarks
    }
    property(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      property_no
      name
    }
    vendor_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
    }
    service_providers_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
    }
    domestic_helper_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
    }
  }
  swift_in_out_log_units(where:{swift_log_id:{eq:$id},is_active:{eq:true}
        client:{eq:$clientId}

  }){
    unit{
     id
      name
      unit_no
    }
  }

}
`;

export const GET_SELECT_GATE = `
query($id:ID){
  access_gates_master(where:{property_id:{eq:$id},   is_active: { eq: true }
        client:{eq:$clientId}

  }){
   value: id
    label:name
  }
}`;

export const GET_SERVICE_TYPE = `
query($property_id:[String]){
  service_providers_master(where:{property_id:{in:$property_id},is_active:true
        client:{eq:$clientId}

  }){
   value: id
   label: name
  }
}
`;
export const GET_SERVICE_TYPE_OPTIONS = () => gql`
 query($company_id:Int){
   count: service_providers_master(
    where:{
      company_id:{eq:$company_id}
      name:{iregex:$search}
      is_active:{eq:true}
            client:{eq:$clientId}

    }
  ) {
    count:count_id
  }
  service_providers_master(where:{company_id:{eq:$company_id},is_active:true,      client:{eq:$clientId}
}
     offset:$offset
    limit:$limit
    ){
   value: id
   label: name
  }
}
`;
export const GET_VENDOR_TYPE = `
query($property_id:[String]){
  vendor_master(where:{property_id:{in:$property_id},is_active:true,      client:{eq:$clientId}
}){
    value:id
    label:name
  }
  unit(where: { property_id: { in: $property_id }, is_active: { eq: true },      client:{eq:$clientId}
 }) {
    value: id
    label: name
    unit_no
    property_id
    property(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      access_gates_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        value: id
        label: name
        gate_no
      }
    }
  }
}
`;
export const GET_VENDOR_TYPE_OPTIONS = () => gql`

query($property_id:[String]){
   count: vendor_master(
    where:{
      name:{iregex:$search}
      is_active:{eq:true}
      client:{eq:$clientId}

    }
  ) {
    count:count_id
  }
  vendor_master(where:{
    is_active:true,
    client:{eq:$clientId}
    }
    offset:$offset
    limit:$limit
    ){
    value:id
    label:name
  }

}
`;
export const GET_UNIT_AGAINST_PROPERTY = () => {
  return gql`
query($property_id:[String]){
     count: unit(
        where: {
          name: { in: $property_id }
          is_active: { eq: true }
          name: { iregex: $search }
                client:{eq:$clientId}

        }
      ) {
        count: count_id
      }
  unit(where: { property_id: { in: $property_id }, is_active: { eq: true },      client:{eq:$clientId}
 }
    offset:$offset
    limit:$limit) {
    value: id
    label: name
    unit_no
    property_id
    property(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      access_gates_master(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        value: id
        label: name
        gate_no
      }
    }
  }
}
`};


export const GET_PROPERTY_BY_UNITID = () => {
  return gql`
    query GET_PROPERTY_BY_UNITID {
      count: unit(
        where: {
          name: { in: $unit_id }
          is_active: { eq: true }
          name: { iregex: $search }
                client:{eq:$clientId}

        }
      ) {
        count: count_id
      }
      unit(
        where: {
          name: { in: $unit_id }
          is_active: { eq: true }
          name: { iregex: $search }
                client:{eq:$clientId}

        }
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
        property {
          id
          name
        }
      }
    }
  `;
};

//get gate
export const GET_GATES = () => {
  return gql`
    query GET_GATES{
      count: access_gates_master(
        where: {
          property_id: { eq: $property_id }
          is_active: { eq: true }
          name: { iregex: $search }
                client:{eq:$clientId}

        }
      ) {
        count: count_id
      }
      access_gates_master(
        where: {
          property_id: { eq: $property_id }
          is_active: { eq: true }
          name: { iregex: $search }
                client:{eq:$clientId}

        }
        offset: $offset
        limit: $limit
      ) {
        value: id
        label: name
        company {
          currency_id
        }
      }
    }
  `;
};
// list

export const GET_LIST = `
query($property_id: [String], $type: String) {
  swift_in_out_log(
    where: {
    property_id: { in: $property_id }
      visitor_type: { eq: $type }
      is_active: true
            client:{eq:$clientId}

  }
    order_by: { created_at: desc }
  ) {
    id
    person_name
    created_at
    visitor_type
    created_by {
      first_name
      last_name
    }
    status
    reference_id
    property {
      name
    }
    check_in_time
    check_out_time
    service_providers_master {
      id
      name
    }
    vendor_master {
      id
      name
    }
  }
}
`;

// domestic helper

// export const GET_DOMESTIC_HELPER = `
// query($qr_code: String, $property_id: String) {
//   domestic_helper_request(
//     where: {
//       domestic_helper_master: { qr_code: { eq: $qr_code } }
//       is_active: true
//       request_status: { eq: "Approved" }
//       property_id: { eq: $property_id }
//     }
//   ) {
//    domestic_helper: id
//     request_status
//     requested_on: created_at
//     domestic_helper_master(where: { is_active: true }) {
//       id
//       name
//       qr_code
//       profession {
//         name
//       }
//       domestic_helper_units {
//         unit {
//           id
//           unit_no
//           name
//         }
//       }
//     }
//   }
// }
// `
//checkin out for domestic

export const GET_DOMESTIC_HELPER = `
query DOMESTIC_HELPER_CHECK_IN {
  swift_in_out_log(
    where: {
      is_active: { eq: true }
      helper: { qr_code: { eq: $qrCode } }
            client:{eq:$clientId}

    }
    order_by:{created_at:desc}
    limit:1
  ) {
    id
    check_in_time
    check_in_by(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      first_name
      last_name
    }
    check_out_time
    check_out_by(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      first_name
      last_name
    }
    helper(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      qr_code
      profession(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        name
      }
    }
  }
  domestic_helper_units(
    where: {
      is_active: { eq: true },
       helper: { qr_code: { eq: $qrCode } },
            client:{eq:$clientId}

      }
  ) {
    id
    unit(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      unit_no
    }
    helper(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      qr_code
      profession(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        name
      }
    }
  }
}`;

export const GET_DOMESTIC_CHECK_IN_OUT = `
query($swift_id:String){
  swift_in_out_log(where:{id:{eq:$swift_id},is_active: { eq: true },
          client:{eq:$clientId}

  }){
    id
    check_in_time
    check_out_time
        check_in_by{
          first_name
          last_name
        }
            check_out_by{
               first_name
          last_name
            }

  }
}
`;

//filter unit
export const GET_FILTER_UNITS = `
query($user_profile_id:String,$role_id:String){
 access_control(where:{user_profile_id:$user_profile_id,roles_id:$role_id,
      client:{eq:$clientId}

},is_active:true){
  property{
    value:id
    label:name
    unit{
      value:id
      label:name
    }
  }
}
}`;
//domestic checkout
export const DomesticHelperCheckout = `
query DOMESTIC_HELPER_CHECKED_OUT {
  swift_in_out_log(
    where: { is_active: { eq: true }, id: { eq: $swiftInOutLogID }
        client:{eq:$clientId}
  }
  ) {
    id
    reference_id
    created_at
    check_in_time
    check_in_by(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      first_name
      last_name
    }
    check_out_time
    check_out_by(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      first_name
      last_name
    }
    status
    helper(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      qr_code
      profession(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        name
      }
    }
  }
  domestic_helper_units(
    where: { is_active: { eq: true }, helper: { qr_code: { eq: $qrCode } },
        client:{eq:$clientId}

  }
  ) {
    id
    unit(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      unit_no
    }
    helper(where: { is_active: { eq: true },client:{eq:$clientId} }) {
      id
      name
      qr_code
      profession(where: { is_active: { eq: true },client:{eq:$clientId} }) {
        id
        name
      }
    }
  }
}

`;

export const GET_PROOF = `
query{
  Identification_master{
    value:id
    label:type
  }
}`;

// const GET_FILTER_UNIT_WITH_PROPERTY = `
// query($user_profile_id: String, $role_id: String) {
//   access_control(
//     where: { user_profile_id: $user_profile_id, roles_id: $role_id }
//     is_active: true
//   ) {
//     property {
//       unit {
//         value: id
//         label: name
//         property {
//           id
//           name
//         }
//       }
//     }
//   }
// }
// `;

export const GETPROFILEDETAILS = `
query Profile($userID: String) {
  user_profiles(where: { id: { eq: $userID } }, is_active: true) {
    id
    first_name
    mobile_no_country_code
    last_name
    contact_id
    contactByID {
      id
      street_1
      street_2
      street_3
      district
      country
      state
      zipcode
      latitude
      longitude
      state
    }
    image_url
    email_id
    mobile_no
    mobile_no_country_code
    alternative_mobile_no
    alternative_mobile_no_country_code
    alternative_email_id
  }
  role: client_user_roles(
    where: {
      user_profile_id: { eq: $userID }
      roles_id: { eq: $roleID }
      is_active: { eq: true }

    }
  ) {
    id
    user_profile_id
    client_role_no
    is_active
  }
}

`;

export const GET_DETAILS_FOR_PROFILE_SCREEN = `
query getDetailsForProfileScreen(
  $referenceID: String
  $userProfileID: String
  $contactID: ID
) {
  assets(
    where: {
      reference_id: { eq: $referenceID }
      assets_master: { id: { eq: 2 } }
      is_active: true
    }
  ) {
    id
    reference_id
    url
    priority
    assets_master_type
    is_active
  }
  client_user_roles(
    where: { user_profile_id: { eq: $userProfileID }, is_active: { eq: true } }
  ) {
    id
    user_profile_id
    client_role_no
    is_active
  }
  contact(where: { id: { eq: $contactID }, is_active: { eq: true } }) {
    id
    street_1
    street_2
    street_3
    district
    city
    country
    state
    zipcode
    latitude
    longitude
    is_active
  }
}
`;


export const GET_WELCOME_BANNER = gql`
query {
  company_master(where: { id: $id }) {
    id
    welcome_screen_logo
  }
}
`
export const GET_ANNOUNCEMENT_BY_ID = `
query($id: ID) {
  notifications(where: { id: { eq: $id } }) {
    id
    title
    description
    notifications_type
    content_type
    triggered_by
    created_at
    category
    created_by {
      id
      first_name
      last_name
      get_assets_url_id
    }
  }
}`;

export let GET_PARKING_AREA = (parkingAreaType) => gql`
query GET_PARKING_AREA($client_ID: String, $property_ID: String, $search_text: String, $parkingAreaType: [String]) {
  parking_area(
    where: {
      client: { eq: $client_ID }
      is_active: { eq: true }
      property_id: { eq: $property_ID }
      vehicle_parking_area: { iregex: $search_text }
      ${parkingAreaType?.length > 0 ? `parking_area_type: { in: $parkingAreaType}` : ``}
    }
  ) {
    name: vehicle_parking_area
    type: parking_area_type
  }
}`

export const GET_PARKING_GROUPING_MASTER = `
query GET_PARKING_GROUPING_MASTER($property_ID: String) {
  parking_grouping_master(
    where: {
      is_active: { eq: true }
      property_id: { eq: $property_ID }
    }
  ) {
    label: name
    value: id
  }
}`