import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SelectBox, TextBox } from "../../components";
import { config } from "../../config";
import { UPDATE_DELIVERY_STATUS } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AlertContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: "white",
    padding: "12px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },
  input: {
    marginTop: "10px",
  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
}));
const InitialState = {
  remarks: "",
  gate: "",
  error: {
    remarks: "",
    gate: "",
  },
};
export const DetailForm = (props) => {
  const classes = useStyles(props);
  const alert = React.useContext(AlertContext);
  const [data, setData] = React.useState({ ...InitialState });
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const isIamValideTostep = () => {
    let isValid = true;
    let error = data.error;
    //Checking Remarks
    if (data.remarks.length === 0) {
      isValid = false;
      error.remarks = "Remarks is Required";
    }
    //Checking gate
    if (data.gate.length === 0) {
      isValid = false;
      error.gate = "Gate is Required";
    }

    setData({ ...data, error });
    return isValid;
  };
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setData({ ...data, [key]: value, error });
  };
  const submit = () => {
    if (isIamValideTostep()) {
      const payload = {
        query: UPDATE_DELIVERY_STATUS,
        variables: {
          id: props?.type,
          data: {
            collected_access_gate: data?.gate?.value,
            remarks: data?.remarks,
            delivery_status: "Received At Gate",
            updated_by: userProfileId,
            updated_at: new Date().toISOString(),
            received_date: new Date().toISOString(),
            delivery_collected_by: userProfileId,
          },
        },
      };
      const payload2 = {
        query: UPDATE_DELIVERY_STATUS,
        variables: {
          id: props?.type,
          data: {
            collected_access_gate: data?.gate?.value,
            remarks: data?.remarks,
            delivery_status: "Collected",
            updated_by: userProfileId,
            updated_at: new Date().toISOString(),
            delivery_collected_date: new Date().toISOString(),
          },
        },
      };

      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        props?.detail?.delivery_status === "Yet To Receive"
          ? payload
          : payload2,
        null,
        true,
        false
      )
        .then((response) => {
          props?.reload();
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
          });
        });
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={classes.cardContent}>
        <div className={classes.input}>
          <SelectBox
            label="Gate Number"
            placeholder="Select Gate"
            options={props?.gate}
            onChange={(value) => updateState("gate", value)}
            value={data?.gate}
            isError={data?.error?.gate?.length > 0}
            errorMessage={data?.error?.gate}
          />
        </div>
        <div className={classes.input}>
          <TextBox
            multiline
            label="Remark"
            placeholder="Enter Remark"
            value={data?.remarks}
            onChange={(value) => updateState("remarks", value.target.value)}
            isError={data?.error?.remarks?.length > 0}
            errorMessage={data?.error?.remarks}
          />
        </div>
        <div className={classes.input}>
          <Button
            variant="contained"
            fullWidth
            className={classes.submitbtn}
            onClick={submit}
            disabled={props?.detail?.delivery_status === "Collected" ? true : false}
          >
            {props?.detail?.delivery_status === "Received At Gate" &&
              "Update As Collected"}
            {props?.detail?.delivery_status === "Yet To Receive" &&
              "Update As Received"}
            {props?.detail?.delivery_status === "Collected" &&
              "Update As Collected"}
          </Button>
        </div>
      </div>
    </>
  );
};
