import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TabsComponent, TopNavBars } from "../../components";
import { LocalStorageKeys } from "../../utils";
import Delivery from "./delivery";
import GatePass from "./gatePass";
import Parking from "./parking";
import SwiftInOut from "./swiftInOut";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

export const Requset = (props) => {
  const classes = useStyles();
  const selected = localStorage.getItem(LocalStorageKeys.selected);
  const [selectedTab, setSelectedTab] = React.useState(
    selected ? parseInt(selected) : 0
  );
  // Top Tabs Funnctions
  const onTab1Clicked = () => {
    setSelectedTab(0);
    localStorage.setItem("selected", 0);
  };
  const onTab2Clicked = () => {
    setSelectedTab(1);
    localStorage.setItem("selected", 1);
  };
  const onTab3Clicked = () => {
    setSelectedTab(2);
    localStorage.setItem("selected", 2);
  };
  const onTab4Clicked = () => {
    setSelectedTab(3);
    localStorage.setItem("selected", 3);
  };
  return (
    <div className={classes.root}>
      <TopNavBars />
      <TabsComponent
        selectedTab={selectedTab}
        onTab1Clicked={() => onTab1Clicked()}
        onTab2Clicked={() => onTab2Clicked()}
        onTab3Clicked={() => onTab3Clicked()}
        onTab4Clicked={() => onTab4Clicked()}
      />
      {selectedTab === 0 && <SwiftInOut />}
      {selectedTab === 1 && <GatePass />}
      {selectedTab === 2 && <Parking />}
      {selectedTab === 3 && <Delivery />}
    </div>
  );
};
