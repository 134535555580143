import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  tabroot: {
    // display: "flex",
    // alignItems: "center",
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "#5078E1",
    padding: "0px",
    minHeight: "auto",
    borderRadius: "4px",
    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "4px",
    },
    minWidth: "auto",
    marginRight: "8px",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "#5078E1",
    marginRight: "8px",
    padding: "0px",
    borderRadius: "4px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },
  title: {
    padding: "8px 14px",
    borderRadius: "4px",
    display:"flex",
    alignItems:"center",
    color: "#4E5A6B",
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "4px",
    backgroundColor: "#5078E1",
    color: "white",
    display:"flex",
    alignItems:"center",
  },
}));
export const TabComponent = (props) => {

  const {
    selectedTab = 0,
    onTab1Clicked = () => false,
    onTab2Clicked = () => false,
    onTab3Clicked = () => false,
  } = props

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Tab Section */}
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            indicatorColor="none"
            textColor="secondary"
            className={classes.tabroot}
            variant="scrollable"
            visibleScrollbar={false}
            scrollButtons={false}
          >
            <Tab
              className={
                selectedTab === 0 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 0 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 0 && (
                    <img
                      src="/images/visitor_parking.svg"
                      alt="img3"
                    />
                  )}
                  &nbsp;&nbsp;Visitor Parking
                </span>
              }
              onClick={onTab1Clicked}
            />
            <Tab
              className={
                selectedTab === 1 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 1 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 1 && (
                    <img src="/images/reserved_parking.svg" alt="img1" />
                  )}
                  &nbsp;&nbsp;Reserved Parking
                </span>
              }
              onClick={onTab2Clicked}
            />
            <Tab
              className={
                selectedTab === 2 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 2 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 2 && (
                    <img
                      src="/images/common_parking.svg"
                      alt="img2"
                    />
                  )}
                  &nbsp;&nbsp;Common Parking
                </span>
              }
              onClick={onTab3Clicked}
            />
          </Tabs>
        </Grid>
      </Grid>
    </div>
  );
};
