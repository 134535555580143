import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import ImageViewer from "react-simple-image-viewer";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,

    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },

  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },

  sub: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    textTransform: "capitalize",
  },
  view: {
    fontSize: "12px",
    color: "#5078E1",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    textTransform: "capitalize",
  },

  checksub: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  checkheading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    marginTop: "10px",
  },
  bottom: {
    padding: "12px",
    marginTop: "-20px",
  },
  heading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    marginTop: "10px",
  },
}));

export const VisiterCard = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.card}>
        <Box alignItems="center" display="flex" p={2}>
          <Box>
            {props?.data?.visitor_image ?
              <Avatar src={props?.data?.visitor_image ? props?.data?.visitor_image : ""} onClick={() => props?.openImageViewer([props?.data?.visitor_image])}>
              </Avatar>
              :
              <Avatar>
              </Avatar>
            }

            {props?.isViewerOpen && (
              <ImageViewer
                src={props?.currentImage}
                currentIndex={0}
                onClose={props?.closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )}
            {/* <img
              src={props?.data?.visitor_image}
              alt=""
              style={{
                borderRadius: "50%",
                objectFit: "cover",
                height: "50px",
                width: "50px",
                marginTop: "4px",
              }}
            /> */}
          </Box>
          <Box flexGrow={1} marginLeft="10px">
            <Typography variant="subtitle2" className={classes.title}>
              {props?.data?.name}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.view}
              noWrap
              onClick={() => props.onClick(props.data)}
            >
              View Details
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Grid container spacing={1} className={classes.bottom}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.heading}>
              Checked In
            </Typography>
            <Typography variant="subtitle2" className={classes.title} >
              {props?.data?.check_in_time ?
                <>
                  By {props?.data?.check_in_by},
                  {moment(props?.data?.check_in_time)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY  hh:mm:a")}
                </>
                : " - "}
            </Typography>

          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.heading}>
              Checked Out
            </Typography>
            <Typography variant="subtitle2" className={classes.title} >
              {props?.data?.check_out_time ?
                <>
                  By {props?.data?.check_out_by},
                  {moment(props?.data?.check_out_time)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY  hh:mm:a")}
                </>
                : " - "}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
