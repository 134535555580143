import * as React from "react"

const PhoneOne = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} {...props}>
    <g data-name="Group 96919">
      <path data-name="Rectangle 53192" fill="none" d="M0 0h22v22H0z" />
      <path
        data-name="icons8-call (1)"
        d="m17.25 18.371-.716.652a2.794 2.794 0 0 1-2.705.565q-3.443-1.055-6.432-5.947T5.16 5.42A2.589 2.589 0 0 1 7.046 3.49l.957-.256a1.99 1.99 0 0 1 2.276 1.058l.816 1.687A1.743 1.743 0 0 1 10.7 8.06L9.44 9.184a.655.655 0 0 0-.218.599 7.513 7.513 0 0 0 .9 2.44 7 7 0 0 0 1.625 1.957.65.65 0 0 0 .595.113l1.819-.54a1.986 1.986 0 0 1 2.126.718l1.136 1.508a1.768 1.768 0 0 1-.173 2.392Z"
        fill="#5078e1"
      />
    </g>
  </svg>
)

export default PhoneOne
