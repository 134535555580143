import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 12px",
  },
  Cardcontent: {
    [theme.breakpoints.up("sm")]: {
      padding: "8px 12px",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "8px 0px",
    },
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  yetto: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5078E11E",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  received: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#FF9340",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  receivedDetail: {
    fontSize: "12px",
    color: "#FF9340",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#FFF4EB",
    padding: "1px 8px",
    borderRadius: "4px",
    marginTop: "6px",
    display: "inline",
  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  collected: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
  },
  img: {
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
}));

export const ServiceCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <div
        className={classes.card}
        onClick={() =>
          history.push(Routes.details + "?passId=" + props?.data?.id)
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box className={classes.img}>
              <img
                src={props?.data?.img}
                alt=""
                style={{ objectFit: "contain", marginTop: "4px" }}
              />
            </Box>
            <Box flexGrow={1} marginLeft="10px">
              <Box display="flex" alignItems="center">
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    {" "}
                    {props?.data?.title}
                  </Typography>
                </Box>

                <Box marginLeft="10px">
                  {!props?.data?.check_out_time && (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      className={classes.yetto}
                    >
                      Yet To Checkout
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box alignItems="center" display="flex" marginTop="4px">
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {props?.data?.subtitle1}
                  </Typography>
                </Box>
                <Box className={classes.dot} />
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {props?.data?.subtitle2}
                  </Typography>
                </Box>
                <Box className={classes.dot} />
                <Box>
                  {props?.Title === "Service" ? (
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.subtitle3}
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.check_out_time
                        ? "Out " +
                        moment(props?.data?.check_out_time)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY hh:mm:a")
                        : "In " +
                        moment(props?.data?.check_in_time)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY hh:mm:a")}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              <ArrowForwardIosIcon
                style={{ color: "#98A0AC", fontSize: "14px" }}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
