import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    minHeight: "90px",
    display: "flex"
  },
  Cardcontent: {
    padding: "8px 16px",
  },
  title: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  count: {
    fontSize: "20px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },

  image: {
    width: 46,
    height: 46,
    backgroundColor: (props) => props?.data?.color,
  },
  sideBorder: {
    backgroundColor: (props) => props?.data?.borderColor,
    margin: " 3px 0px",
    borderRadius: " 0px 10px 10px 0px",
    border: (props) => `3px solid ${props?.data?.borderColor}`
  }
}));

export const HomepageCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <div
        className={classes.card}
        onClick={() => {
          history.push({
            pathname: props?.data?.link,
            state: {
              type: props?.data?.redirectLink,
            },
          });

          localStorage.setItem(
            "selected",
            props?.data?.redirect ? props?.data?.redirect : 0
          );
        }}
      >        <Box className={classes.sideBorder} />
        <div className={classes.Cardcontent}>
          <Grid container alignItems="center" justifyContent={"space-between"}>
            <Grid item xs={8} sm={9} justifyContent={"space-between"}>
              <Typography variant="body1" className={classes.count}>
                {props?.data?.count}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3} alignItems={"end"}>
              <img src={props?.data?.image} alt="" />
            </Grid>
            <Grid item xs={12}>
              {props?.entries ? (
                <>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    {props?.data?.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    {props?.data?.name1}
                  </Typography>
                </>
              ) : (
                <Typography variant="subtitle2" className={classes.title}>
                  {props?.data?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
