import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import CreatableSelect from "react-select/creatable";

const useStyles = makeStyles((theme) => ({
  createBtn: {
    display: "inline-flex",
    cursor: "pointer",
  },
  selects: {

    "& .css-1rhbuit-multiValue": {
      backgroundColor: "#DBEDFE",
    },
    "& .css-183jcic-control": {
      height: "auto !important",
    },
    "& .css-xab9ju-control": {
      height: "auto",

    }
  },
  label: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
  },
}));

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: 8,
    height: "auto",
    fontSize: "16px",
    minHeight: 45,
    border: "1.5px solid #E2E2E2",
    boxShadow: "none",
    "&:hover": {
      border: "1.5px solid #E2E2E2",
    },
  }),
};

export const SelectBox = ({
  label = "",
  isRequired = false,
  isPaginate = false,
  createSelect = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isError = false,
  errorMessage = "",
  isMulti = false,
  isDisabled = false,
  menuPlacement = "auto"
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div style={{ display: "flex" }}>
        {
          <Typography
            variant="body1"
            color="textsecondary"
            className={classes.label}
            align="left"
            gutterBottom
          >
            {label}
          </Typography>
        }
        {isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>

      {isPaginate ? (
        <AsyncPaginate
          isClearable
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={customStyles}
          isMulti={isMulti}
          menuPlacement={menuPlacement}

        />
      ) : createSelect ? (
        <CreatableSelect
          isClearable={false}
          options={options}
          isLoading={loading}
          placeholder={placeholder}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          onChange={onChange}
          styles={customStyles}
          menuPlacement={menuPlacement}

        />
      ) : (
        <Select
          isClearable={false}
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={customStyles}
          className={classes.selects}
          isMulti={isMulti}
          isDisabled={isDisabled}
          menuPlacement={menuPlacement}

        />
      )}
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
