import {
  Badge,
  Box,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Filter, LoaderSimmer, SwiftCard } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  DOMESTIC_SERVICE_VENDOR,
  GET_FILTER_UNITS,
  GET_PROPERTY_BY_ROLE
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, customEnumModifier, enumSelect, enumTypes, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  loader: {
    textAlign: "center",
    marginTop: "50%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
}));

const InitialDate = {
  startDate: null,
  endDate: null,
};

const SwiftInOut = (props) => {
  const { t } = props;

  const classes = useStyles();
  const size = useWindowDimensions();
  const [list, getList] = React.useState([]);
  const [property, setProperty] = React.useState([]);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [filter, setFilter] = React.useState(false);
  // const [unitList, setUnitList] = React.useState([]);

  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;

  const alert = React.useContext(AlertContext);
  const { state } = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [enumValue, setEnumValue] = React.useState({
    type: [],
    status: [],

  })
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const getListFromdashboard = (val, type, status, offset, limit) => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/security-dashboard/${state?.type}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let dataList = response?.data?.data?.map((val) => {
          return {
            id: val?.id,
            title: "Domestic Helper",
            subtitle1: val?.gatename,
            subtitle2: `${val?.visitorname}
                  (${val?.professionname})`,

            type: val?.visitor_type,
            img: "/images/pass.svg",
            check_out_time: val?.checkedouttime,
            check_in_time: val?.checkedintime,
          };
        });
        getList(dataList);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getPassList = (val, visitorType) => {
    const data = {
      query: DOMESTIC_SERVICE_VENDOR,
      variables: {
        visitorType: visitorType,
        property_id: val,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let YetToCheckout = response?.data?.data?.swift_in_out_log.filter(
          (val) => val.check_in_time !== null && val.check_out_time === null
        );
        let Checkout = response?.data?.data?.swift_in_out_log.filter(
          (val) => val.check_in_time !== null && val.check_out_time !== null
        );
        let dataList = YetToCheckout.concat(Checkout)?.map((val) => {
          if (val?.visitor_type === visitorType?.[1]) {
            return {
              id: val?.id,
              title: "Service Provider",
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.service_providers_master?.name,
              type: val?.visitor_type,
              img: val?.service_providers_master?.image_url,
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              property: val?.property?.name,
              referenceId: val?.reference_id,
              person_name: val?.person_name,
              unit: val?.unit?.[0]?.unit?.unit_no
            };
          }
          if (val?.visitor_type === visitorType?.[2]) {
            return {
              id: val?.id,
              title: "Domestic",
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.domestic_helper_master?.name ?? "",
              type: val?.visitor_type,
              img: "/images/pass.svg",
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              subtitle2SP: val?.service_providers_master?.name,
              property: val?.property?.name,
              referenceId: val?.reference_id,
              person_name: val?.person_name,
              unit: val?.unit?.[0]?.unit?.unit_no

            };
          }
          if (val?.visitor_type === visitorType?.[0]) {
            return {
              id: val?.id,
              title: "Vendor",
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.vendor_master?.name,
              type: val?.visitor_type,
              img: val?.vendor_master?.image_url,
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              property: val?.property?.name,
              referenceId: val?.reference_id,
              person_name: val?.person_name,
              unit: val?.unit?.[0]?.unit?.unit_no

            };
          }

          return 0;
        });
        getList(dataList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getPassList2 = (val, type) => {
    const data = {
      query: DOMESTIC_SERVICE_VENDOR,
      variables: {
        visitorType: type,
        property_id: val,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let YetToCheckout = response?.data?.data?.swift_in_out_log.filter(
          (val) => val.check_in_time !== null && val.check_out_time === null
        );
        let Checkout = response?.data?.data?.swift_in_out_log.filter(
          (val) => val.check_in_time !== null && val.check_out_time !== null
        );

        let dataList = YetToCheckout.concat(Checkout)?.map((val) => {
          if (val?.visitor_type === "SP") {
            return {
              id: val?.id,
              title: "Service Provider",
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.service_providers_master?.name,
              type: val?.visitor_type,
              img: val?.service_providers_master?.image_url,
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              property: val?.property?.name,
              referenceId: val?.reference_id,
              unit: val?.unit?.[0]?.unit?.unit_no

            };
          }
          if (val?.visitor_type === "DH") {
            return {
              id: val?.id,
              title: "Domestic Helper",
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.domestic_helper_master?.name ?? "",
              // subtitle2: val?.domestic_helper_master?.name ?? `${val?.domestic_helper_request?.helper?.name ?? ""}
              //     (${val?.domestic_helper_request?.helper?.profession_master?.description ?? ""})`,
              type: val?.visitor_type,
              img: "/images/pass.svg",
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              subtitle2SP: val?.service_providers_master?.name,
              property: val?.property?.name,
              referenceId: val?.reference_id,
              unit: val?.unit?.[0]?.unit?.unit_no

            };
          }
          if (val?.visitor_type === "VE") {
            return {
              id: val?.id,
              title: "Vendor",
              subtitle1: val?.access_gates_master?.name,
              subtitle2: val?.vendor_master?.name,
              type: val?.visitor_type,
              img: val?.vendor_master?.image_url,
              check_out_time: val?.check_out_time,
              check_in_time: val?.check_in_time,
              property: val?.property?.name,
              referenceId: val?.reference_id,
              unit: val?.unit?.[0]?.unit?.unit_no

            };
          }
          return 0;
        });
        getList(dataList);
        setFilter(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_PROPERTY_BY_ROLE,
      variables: {
        user_profile_id: userProfileId,
        role_id: roleId,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let a = [];
        response?.data?.data?.access_control?.map((val, index) => {
          let _a = {};
          _a.value = val?.property?.value;
          _a.label = val?.property?.label;
          a.push(_a);
          return 0;
        });
        setProperty(a);

        getEnum(response?.data?.data?.access_control?.map((val) => val?.property?.value))


      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };

  const filterUnits = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_FILTER_UNITS,
      variables: {
        user_profile_id: userProfileId,
        role_id: roleId,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let dd = [];
        response?.data?.data?.access_control?.map((val, index) => {
          val?.property.unit?.map((item) => {
            let _d = {};
            _d.value = val?.property?.value;
            _d.label = item?.label;
            dd.push(_d);
            return 0;
          });
          return 0;
        });
        // setUnitList(dd);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  //filter json
  const filterData = [
    {
      id: "1",
      title: "Type",
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: enumValue?.status ?? []
    },

    {
      id: "2",
      title: "Property",
      key: "property",
      filterType: "CHECKBOX",
      values: property,
    },
    {
      id: "3",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];



  //apply filter
  const applyFilter = (data) => {
    if (data?.type.length > 0) {
      if (data?.property.length > 0) {
        setSelectedFilter(data);
        getPassList2(
          data.property,
          data.type,
          data.status,
          dateFilter,
          filter,
          0
        );
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Select Property",
        });
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Select Type",
      });
    }
    // setOffset(0);
  };
  //enum
  const getEnum = async (data) => {
    const result = await enumSelect([enumTypes?.swift_type])
    let requestSourceMap = result?.swift_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let VendorEntry = {
      replaceKey: "Vendor Entry",
      actualKey: "VE",
      data: result?.swift_type
    }
    let status = customEnumModifier(VendorEntry)

    let serviceProvider = {
      replaceKey: "Service Provider",
      actualKey: "SP",
      data: status
    }

    status = customEnumModifier(serviceProvider)
    let domesticHelper = {
      replaceKey: "Domestic Helper",
      actualKey: "DH",
      data: status
    }
    status = customEnumModifier(domesticHelper)
    setEnumValue({ ...enumValue, status: status, type: requestType })
    getPassList(data, ["VE", "SP", "DH"]);

  }
  React.useEffect(() => {
    if (clientId) {
      if (state?.type === "domesticHelpers") {
        getListFromdashboard();
      } else {
        getPropertyId();

      }
      filterUnits();
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <LoaderSimmer count={10} />
      ) : (
        <div className={classes.root}>
          <Box display="flex" alignItems="center" p={1}>
            <Box flexGrow={1}>
              {/* <TextField
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Search Parking Slot"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              height: "30px",
            }}
            fullWidth
            className={classes.search}
          /> */}
              <Typography className={classes.title}>
                {t("Swift_in_&_Out")} ({list?.length})

              </Typography>
            </Box>
            <Box marginLeft="10px">
              <IconButton
                className={classes.filter}
                onClick={() => setFilter(true)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <div
            style={{
              height: size.height - 210,
              overflow: "scroll",
              marginRight: "-3px",
            }}
          >
            <Box padding={"16px 22px 16px 16px"}>
              {list?.map((val) => (
                <Box marginBottom={"10px"}>
                  <SwiftCard data={val} />
                </Box>
              ))}
            </Box>

          </div>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={filter}
              onClose={() => setFilter(false)}
            >
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  type: selectedFilter.type,
                  status: selectedFilter.status,
                  unit: selectedFilter.unit,
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => setFilter(false)}
                onApply={applyFilter}
                onClose={() => setFilter(false)}
                updateState={updateState}
                data={dateFilter}
              />
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer open={filter} onClose={() => setFilter(false)}>
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  type: selectedFilter.type,
                  status: selectedFilter.status,
                  unit: selectedFilter.unit,
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => setFilter(false)}
                onApply={applyFilter}
                onClose={() => setFilter(false)}
                updateState={updateState}
                data={dateFilter}
              />
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default withNamespaces("gatePass")(SwiftInOut);

