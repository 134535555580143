import { Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { LoaderSimmer, Notification, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { UNREADNOTIFICATION } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { AlertProps, enumSelect, enumCatagory } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  notificationroot: {
    padding: "0px 8px 8px 12px",
  },
  nodata: {
    marginTop: "30%",
    textAlign: "center",
    fontSize: "16px",
    color: "gray",
  },
  tabroot: {
    display: "flex",
    alignItems: "center",
    padding: "7px",
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "#071741",
    padding: "0px",
    minHeight: "auto",
    borderRadius: "20px",
    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "20px",
    },
    minWidth: "auto",

    marginRight: "8px",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "20px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },
  title: {
    padding: "8px 14px",
    borderRadius: "20px",
    background: theme.palette.background.paper,
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "20px",
    backgroundColor: "#071741",
    color: "white",
  },
  imageCard: {
    height: "75px",
    width: "100%",
    display: 'flex'
  },
}));
export const Notifications = (props) => {
  const { t } = props
  const classes = useStyles();
  const size = useWindowDimensions();
  const [data, setData] = React.useState([]);
  let companyId = JSON.parse(localStorage.getItem("accessType"));
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [value, setValue] = React.useState("1");
  const alert = React.useContext(AlertContext);
  const [enumValue, setEnumValue] = React.useState({
    type: {}
  });
  const [tabstate, settabstate] = React.useState([
    "Announcement",
    "Push-Notification",
  ]);

  //tab select
  const handleChange = (event, newValue) => {
    setOffset(0);
    if (newValue === "3") {
      getNotification(["Push-Notification"], 0, true);
      settabstate(["Push-Notification"]);
    }
    if (newValue === "2") {
      getNotification(["Announcement"], 0, true);
      settabstate(["Announcement"]);
    }
    if (newValue === "1") {
      getNotification(["Announcement", "Push-Notification"], 0, true);
      settabstate(["Announcement", "Push-Notification"]);
    }
    setValue(newValue);
  };
  const goBack = () => {
    //history.push(Routes.dashboard)
    window.history.go(-1);
  };
  const getNotification = (key, offSet, filter) => {
    const params = {
      company_id: companyId?.map((val) => val?.company_id) ?? [],
      type: key,
      offset: offSet,
      limit: 10,
      is_draft: false,
      user_profile_id: localStorage.getItem(LocalStorageKeys.userId),
      to_builds: [config.app_x_build]
    };
    NetworkCall(
      `${config.api_url}/notifications/list`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setData(response?.data?.data?.response);
        } else {
          setData(data?.concat(response?.data?.data?.response));
        }
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setLoading(false);
      });
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.notifications_content_type])

    let requestSourceMap = stayType?.notifications_content_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    setEnumValue({ ...enumValue, notification: requestType })
    getNotification(
      [requestType?.type1, requestType?.type2],
      0,
      true
    );
  }
  React.useEffect(() => {
    getEnum()
    // eslint-disable-next-line
  }, []);
  const fetchMoreData = () => {
    setOffset(offset + 10);
    getNotification(
      tabstate,
      offset + 10,
      false
    );
  };
  const viewNotification = (data) => {
    const variables = {
      query: UNREADNOTIFICATION,
      variables: {
        id: data?.id,
        updatedData: {
          is_read: true,
        },
      },
    };

    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variables,
      null,
      true,
      false
    )
      .then((response) => {
        getNotification(tabstate, 0, false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        <TitleBar text="Notifications" goBack={goBack} />
        <Grid container style={{ backgroundColor: "#F2F5FA" }}>
          {loading ? (
            <LoaderSimmer card count={8} size={50} />
          ) : (
            <>
              <Grid item xs={12}>
                <Tabs
                  value={value}
                  style={{ backgroundColor: "#fff" }}
                  onChange={handleChange}
                  indicatorColor="none"
                  textColor="secondary"
                  className={classes.tabroot}
                  variant="scrollable"
                  visibleScrollbar={false}
                  scrollButtons={false}
                >
                  <Tab
                    className={
                      value === "1" ? classes.tabItemSelect : classes.tabItem
                    }
                    label={
                      <span
                        className={
                          value === "1" ? classes.titleselect : classes.title
                        }
                      >
                        &nbsp;{t("All")}
                      </span>
                    }
                    value="1"
                  />
                  <Tab
                    className={
                      value === "2" ? classes.tabItemSelect : classes.tabItem
                    }
                    label={
                      <span
                        className={
                          value === "2" ? classes.titleselect : classes.title
                        }
                      >
                        &nbsp;{t("Announcement")}
                      </span>
                    }
                    value="2"
                  />
                  <Tab
                    className={
                      value === "3" ? classes.tabItemSelect : classes.tabItem
                    }
                    label={
                      <span
                        className={
                          value === "3" ? classes.titleselect : classes.title
                        }
                      >
                        &nbsp;&nbsp;{t("Notifications")}
                      </span>
                    }
                    value="3"
                  />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.notificationroot}>
                  {loading ? (
                    <LoaderSimmer card count={10} size={100} />
                  ) : (
                    <>
                      {data?.length > 0 ? (
                        <InfiniteScroll
                          dataLength={data.length}
                          next={fetchMoreData}
                          hasMore={true}
                          height={size?.height - 120}
                        >
                          <div
                            style={{
                              padding: "12px 8px 8px 12px",
                              overflowX: "hidden"
                            }}
                          >
                            {data?.map((val) => {
                              return <Notification data={val} viewNotification={viewNotification} />;
                            })}
                          </div>
                        </InfiniteScroll>
                      ) : (
                        <Typography className={classes.nodata} height={size?.height - 280}>
                          {" "}
                          {t("NoDataFound")}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};
export default withNamespaces("notification")(Notifications)