import {
  Badge,
  Box,
  Dialog,
  Drawer,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Filter, LoaderSimmer } from "../../components";
import { DeliveryCard } from "../../components/cards/deliveryCard";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { GET_FILTER_UNITS, GET_PROPERTY_BY_ROLE } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, enumSelect, enumTypes, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  loader: {
    textAlign: "center",
    marginTop: "50%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const Delivery = (props) => {
  const { t } = props;

  const classes = useStyles();
  const size = useWindowDimensions();
  const { state } = useLocation();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const userProfileId = localStorage.getItem(LocalStorageKeys.userId)

  const [list, setList] = React.useState([]);
  // const [property, setProperty] = React.useState([]);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [filter, setFilter] = React.useState(false);
  const [unitList, setUnitList] = React.useState([]);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [loading, setLoading] = React.useState(true);
  const clientId = JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id;
  const [enumValue, setEnumValue] = React.useState({
    type: [],
    status: [],
    objectKey: {}

  })
  const getListFromdashboard = (val, type, status, offset, limit) => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/security-dashboard/${state?.type}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {

        setList(response?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const getPassList = (val, requestType, status) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: requestType,
      status: status,
      entries: false,
      property_id: val,
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {

        setLoading(false);
        setList(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getPassList2 = (val, status) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: ["DCR"],
      status: status,
      entries: false,
      unit_id: val,
      offset: 0,
      limit: 1000,
      start_date:
        dateFilter?.endDate?.toString().length > 0
          ? dateFilter?.startDate
          : undefined,
      end_date:
        dateFilter?.endDate?.toString().length > 0
          ? dateFilter?.endDate
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {

        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        setList(response?.data?.data);
        setFilter(false);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getPropertyId = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_PROPERTY_BY_ROLE,
      variables: {
        user_profile_id: userProfileId,
        role_id: roleId,
        clientId: clientId
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        // setProperty(response.data.data?.access_control);
        getEnum(response?.data?.data?.access_control?.map((val) => val?.property?.value))

      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };

  //filter json

  const filterData = [
    {
      id: "1",
      title: "Status",
      key: "status",
      showSearch: true,
      filterType: "CHECKBOX",
      values: enumValue?.status ?? []
    },
    {
      id: "3",
      title: "Unit",
      key: "unit",
      filterType: "CHECKBOX",
      values: unitList,
    },
    {
      id: "4",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];
  const filterUnits = () => {
    const roleId = JSON.parse(localStorage.getItem(LocalStorageKeys.accessType))[0]?.id;
    const data = {
      query: GET_FILTER_UNITS,
      variables: {
        user_profile_id: userProfileId,
        role_id: roleId,
        clientId: clientId

      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setUnitList(response?.data?.data?.access_control[0]?.property?.unit);
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  //apply filter
  const applyFilter = (data) => {
    if (data?.unit.length > 0) {
      setSelectedFilter(data);
      getPassList2(
        data?.unit,
        data.status.length > 0
          ? data.status
          : [enumValue?.objectKey?.type1, enumValue?.objectKey?.type2, enumValue?.objectKey?.type3, enumValue?.objectKey?.type4],
        dateFilter,
        filter,
        0
      );
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Select Unit",
      });
    }
  };

  const getEnum = async (data) => {
    const result = await enumSelect([enumTypes?.delivery_status_type, enumTypes?.security_request_type])
    let requestSourceMap = result?.security_request_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap)?.map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    let requestSourceMapStatus = result?.delivery_status_type?.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus)?.map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})
    setEnumValue({ ...enumValue, status: result?.delivery_status_type, type: requestType, objectKey: requestTypeStatus })
    if (data?.length > 0) {

      getPassList(
        data,
        ["DCR"],
        [requestTypeStatus?.type1, requestTypeStatus?.type2, requestTypeStatus?.type3, requestTypeStatus?.type4]
      );
    }
  }

  React.useEffect(() => {
    if (clientId) {
      // getEnum()
      if (state?.type === "deliveryRequest") {
        getListFromdashboard();
      } else {
        getPropertyId();
      }

      filterUnits();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <LoaderSimmer count={10} />

      ) : (
        <div className={classes.root}>
          <Box display="flex" alignItems="center" p={1}>
            <Box flexGrow={1}>
              {/* <TextField
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Search Delivery"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              height: "30px",
            }}
            fullWidth
            className={classes.search}
          /> */}
              <Typography className={classes.title}>
                {t(" Delivery")} ({list?.length})
              </Typography>
            </Box>
            <Box marginLeft="10px">
              <IconButton
                className={classes.filter}
                onClick={() => setFilter(true)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <div
            style={{
              height: size.height - 210,
              overflow: "scroll",
              marginRight: "-4px",
              padding: "16px"
            }}
          >
            {list?.map((val) => {
              return <Box marginBottom={"10px"}>
                <DeliveryCard data={val} status={enumValue?.objectKey} />
              </Box>
            })}
          </div>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth
              open={filter}
              onClose={() => setFilter(false)}
            >
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  status: selectedFilter.status,
                  unit: selectedFilter.unit,
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => setFilter(false)}
                onApply={applyFilter}
                onClose={() => setFilter(false)}
                updateState={updateState}
                data={dateFilter}
              />
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer open={filter} onClose={() => setFilter(false)}>
              <Filter
                filterData={filterData}
                selectedList={filterData[0]}
                filters={{
                  status: selectedFilter.status,
                  unit: selectedFilter.unit,
                }}
                setSelectedFilter={setSelectedFilter}
                onGoBack={() => setFilter(false)}
                onApply={applyFilter}
                onClose={() => setFilter(false)}
                updateState={updateState}
                data={dateFilter}
              />
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default withNamespaces("gatePass")(Delivery);

