import { Avatar, AvatarGroup, Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import ImageViewer from "react-simple-image-viewer";
import { SlideButton } from "../../components";


const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
  },

  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },

  sub: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    textTransform: "capitalize",
  },
  view: {
    fontSize: "12px",
    color: "#5078E1",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    textTransform: "capitalize",
  },
  checksub: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  checksub2: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.palette.primary.main
  },
  checkheading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    marginTop: "10px",
  },
  bottom: {
    padding: "12px",
    marginTop: "-20px",
  },
  span: {
    backgroundColor: "#5078E11E",
    color: "#98A0AC",
    fontSize: "10px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    marginLeft: "6px",
    padding: "4px",
    borderRadius: "4px"

  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    }
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    }
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
}));

export const ServiceVendorCard = (props) => {
  const classes = useStyles(props);
  // const disableButton = ((new Date() <= new Date(props?.parentData?.request_from)) || (new Date() >= new Date(props?.parentData?.request_to))) ? true : false

  return (
    <>
      <div className={classes.card}>
        {props?.data?.visitor_type === "VE" &&
          <>
            <Box alignItems="center" display="flex" p={2}>
              <AvatarGroup>
                {props?.data?.vendor_image ?
                  <Avatar src={props?.data?.vendor_image ? props?.data?.vendor_image : ""} onClick={() => props?.openImageViewer([props?.data?.vendor_image])}>
                  </Avatar>
                  :
                  <Avatar>
                  </Avatar>
                }
                {props?.data?.vehicle_image ?
                  <Avatar src={props?.data?.vehicle_image ? props?.data?.vehicle_image : ""} onClick={() => props?.openImageViewer([props?.data?.vehicle_image])}>
                  </Avatar>
                  :
                  <Avatar>
                  </Avatar>
                }
              </AvatarGroup>
              <Typography variant="subtitle2" className={classes.checksub}>
                Vendor & Vehicle Images
                {(props?.data?.vendor_image?.length > 0 || props?.data?.vehicle_image?.length > 0) &&
                  <Typography
                    variant="subtitle2"
                    className={classes.checksub2}
                    onClick={() => props?.openImageViewer([props?.data?.vendor_image, props?.data?.vehicle_image])}
                  >
                    View Images
                  </Typography>
                }
              </Typography>

              {props?.isViewerOpen && (
                <ImageViewer
                  src={props?.currentImage}
                  currentIndex={0}
                  onClose={props?.closeImageViewer}
                  disableScroll={false}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                  }}
                  closeOnClickOutside={true}
                />
              )}
            </Box>
            <Divider />
          </>


        }
        <Grid container spacing={1} className={classes.bottom}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.checkheading}>
              Checked In
            </Typography>
            {props?.data?.check_in_time ?
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.check_in_byByID?.first_name && "By " + props?.data?.check_in_byByID?.first_name + ","}&nbsp;
                {props?.data?.check_in_time ? moment(props?.data?.check_in_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}
              </Typography> : " - "
            }
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.checkheading}>
              Checked Out
            </Typography>
            {props?.data?.check_out_time ?
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.check_out_byByID?.first_name && "By " + props?.data?.check_out_byByID?.first_name + ","}&nbsp;
                {props?.data?.check_out_time ? moment(props?.data?.check_out_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}
              </Typography> : " - "
            }
          </Grid>
          <Grid item xs={12}>
            <SlideButton
              mainText={`Swipe to ${props?.data?.check_in_time === null && props?.data?.check_out_time === null ? " Check In" : "Check Out"}`}
              overlayText={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "Checked In" : "Checked Out"}
              classList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-class1" : "my-class2"}
              caretClassList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-caret-class1" : "my-caret-class2"}
              overlayClassList={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? "my-overlay-class1" : "my-overlay-class2"}
              onSlideDone={() => props?.data?.check_in_time ? props.onClick(props.data) : false}
              disabled={props?.data?.check_in_time && props?.data?.check_out_time}
            />
            {/* <Button
              fullWidth
              variant="contained"
              onClick={() => props?.data?.check_in_time ? props.onClick(props.data) : false}
              className={props?.data?.check_in_time === null && props?.data?.check_out_time === null ? classes.sucess : classes.warning}
              disabled={props?.data?.check_in_time && props?.data?.check_out_time}
              size="large"
              loading={props?.loading}
              loadingPosition="end"
            >
              {props?.data?.check_in_time === null && props?.data?.check_out_time === null ? " Check In" : "Check Out"}
            </Button> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
